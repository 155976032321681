import ReactHtmlParser from "react-html-parser";
import { Button } from "reactstrap";
import { ANSWER_TYPE } from "@constants";
import { AnswerType, Question } from "@models";

export const Quiz = ({
  currentQuestion,
  currentRound,
  numRounds,
  chosenOption,
  handleAnswerClick,
  questionAnswered,
  answerResult,
  handleNextQuestionClick,
}: {
  currentQuestion: Question;
  currentRound: number;
  numRounds: number;
  chosenOption: string | undefined;
  handleAnswerClick: (option: string) => void;
  questionAnswered: boolean;
  answerResult: AnswerType;
  handleNextQuestionClick: () => void;
}) => {
  if (!currentQuestion) {
    return null;
  }

  const { answer, question, options, category } = currentQuestion;

  return (
    <div className="full-page center mt-5">
      <h3>
        Question {currentRound + 1} of {numRounds}
      </h3>
      <h6 className="mb-5">Category: {category}</h6>
      <h2>{ReactHtmlParser(question)}</h2>
      <div className="quiz__buttons mt-5">
        {options.map((option) => {
          const buttonVariant =
            answer === option
              ? "success"
              : chosenOption === option
              ? "danger"
              : "secondary";

          return (
            <div key={option}>
              <Button
                block
                className="mb-1"
                color={questionAnswered ? buttonVariant : "secondary"}
                onClick={() => handleAnswerClick(option)}
                size="lg"
              >
                {ReactHtmlParser(option)}
              </Button>
            </div>
          );
        })}
      </div>
      <hr />
      {questionAnswered &&
        (answerResult === ANSWER_TYPE.correct ? (
          <p>Well done!</p>
        ) : (
          <p>Nope!</p>
        ))}
      {questionAnswered && (
        <Button color="primary" onClick={handleNextQuestionClick}>
          Next question
        </Button>
      )}
    </div>
  );
};
