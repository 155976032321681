class UtilService {
  getRandomNumber = (max: number) => {
    return Math.floor(Math.random() * max);
  };

  getMedian(numbers: number[]) {
    const sorted = numbers.slice().sort((a, b) => a - b);
    const middle = Math.floor(sorted.length / 2);

    if (sorted.length % 2 === 0) {
      return (sorted[middle - 1] + sorted[middle]) / 2;
    }

    return sorted[middle];
  }

  capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || '';
}

export const utilService = new UtilService();
