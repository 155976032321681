import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

type Props = {
  data: any[];
  reversed?: boolean;
  title: string;
  yAxis: string;
  yAxisDisplayName: string;
};
export const CustomLineChart = ({
  data,
  title,
  yAxis,
  yAxisDisplayName,
  reversed = false,
}: Props) => {
  return (
    <ResponsiveContainer width={'100%'} height={250}>
      <LineChart
        width={1000}
        height={400}
        data={data}
        margin={{
          top: 50,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <text
          x={500 / 2}
          y={20}
          fill="black"
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan fontSize="16">{title}</tspan>
        </text>
        <CartesianGrid stroke="#ccc" />
        <Line name={yAxisDisplayName} type="monotone" dataKey={yAxis} stroke="#8884d8" />
        <Tooltip />
        <XAxis dataKey="date" />
        <YAxis
          type="number"
          domain={['dataMin', 'dataMax']}
          reversed={reversed}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
