import { useState, useEffect } from "react";
import { ANSWER_TYPE } from "@constants";
import { AnswerType, Question } from "@models";
import { opentdbService } from "@services";
import { GameOver, Quiz, Loading } from "@view";
import "./trivia.page.scss";

export const Trivia = () => {
  const numRounds = 10;
  const [questions, setQuestions] = useState<Question[] | undefined>(undefined);
  const [currentQuestion, setCurrentQuestion] =
    useState<Question | undefined>(undefined);
  const [currentRound, setCurrentRound] = useState<number>(0);
  const [answerResult, setAnswerResult] = useState<AnswerType>(
    ANSWER_TYPE.unanswered
  );
  const [chosenOption, setChosenOption] =
    useState<string | undefined>(undefined);
  const [questionAnswered, setQuestionAnswered] = useState<boolean>(false);
  const [score, setScore] = useState<number>(0);
  const [gameOver, setGameOver] = useState<boolean>(false);

  useEffect(() => {
    const fetchQuestions = async () => {
      const qs = await opentdbService.getTriviaRounds(numRounds);
      setQuestions(qs);
    };

    fetchQuestions();
  }, [gameOver, numRounds]);

  useEffect(() => {
    // Set the current round based on the round number.
    if (questions) {
      setCurrentQuestion(questions[currentRound]);
    }
  }, [questions, currentRound, numRounds]);

  const resetGame = () => {
    setScore(0);
    setChosenOption(undefined);
    setQuestionAnswered(false);
    setAnswerResult(ANSWER_TYPE.unanswered);
    setCurrentRound(0);
    setGameOver(false);
  };

  const handleTryAgainClick = () => {
    resetGame();
  };

  const handleAnswerClick = (clickedOption: string) => {
    if (!questionAnswered) {
      setChosenOption(clickedOption);
      setQuestionAnswered(true);
      if (clickedOption === currentQuestion?.answer) {
        setScore(score + 1);
        setAnswerResult(ANSWER_TYPE.correct);
      } else {
        setAnswerResult(ANSWER_TYPE.incorrect);
      }
    }
  };

  const handleNextQuestionClick = () => {
    setChosenOption(undefined);
    setQuestionAnswered(false);
    setAnswerResult(ANSWER_TYPE.unanswered);
    if (currentRound < numRounds - 1) {
      setCurrentRound(currentRound + 1);
    } else {
      setGameOver(true);
    }
  };

  return gameOver ? (
    <GameOver
      onTryAgain={handleTryAgainClick}
      score={score}
      numRounds={numRounds}
    />
  ) : currentQuestion ? (
    <Quiz
      currentQuestion={currentQuestion}
      currentRound={currentRound}
      numRounds={numRounds}
      chosenOption={chosenOption}
      handleAnswerClick={handleAnswerClick}
      questionAnswered={questionAnswered}
      answerResult={answerResult}
      handleNextQuestionClick={handleNextQuestionClick}
    />
  ) : (
    <Loading />
  );
};
