import { Resource } from '@models';
// import { Card, CardText, CardBody, CardTitle } from "reactstrap";
import './resource-card.component.css';

export const ResourceCard = ({
  category,
  categoryData,
}: {
  category: string;
  categoryData: Resource[];
}) => {
  return (
    // <Card>
    //   <CardBody>
    //     <CardTitle tag="h5">{category}</CardTitle>
    //     {categoryData.map(({ title, url }) => {
    //       return (
    //         <CardText key={title}>
    //           <a
    //             href={url}
    //             target="_blank"
    //             rel="noopener noreferrer"
    //             title={title}
    //           >
    //             {title}
    //           </a>
    //         </CardText>
    //       );
    //     })}
    //   </CardBody>
    // </Card>

    <div className="brutal-card">
      <div className="header">{category}</div>
      <div className="content">
        {categoryData.map(({ title, url }) => {
          return (
            <div key={title}>
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                title={title}
              >
                {title}
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};
