import { useEffect, useState } from "react";
import { SimpleQuestion } from '@models';
import { spreadsheetService } from '@services';

import "./test.page.scss";

export const Test = () => {
  const [questions, setQuestions] = useState<SimpleQuestion[]>([]);
  // eslint-disable-next-line
  const [currentIndex, setCurrentIndex] = useState(0);
  const [userAnswer, setUserAnswer] = useState('');

  useEffect(() => {
    const getQuestions = async () => {
      try {
        const tempQuestions = await spreadsheetService.getSimpleQuestionsFromSpreasheet();

        setQuestions(tempQuestions);
      } catch (error) {
        console.log(error);
      }
    };

    getQuestions();
  }, []);

  const handleChange = (event: any) => {
    const userInput = event.target.value
    setUserAnswer(userInput);
    if (userInput === questions[currentIndex].answer) {
      console.log('CORRECT!');
    } else {
      console.log('NOT YET CORRECt')
    }
  }

  return (
    <div className="crazy-container">
      <div className="box">
        {questions.length === 0
          ? <div>loading...</div>
          : <div key={questions[currentIndex].question}>
            <p>{questions[currentIndex].question}</p>
            <input id="answer" type="text" onChange={handleChange} value={userAnswer} />
          </div>
        }
      </div>
    </div>
  )
};
