import { CATEGORY_TYPE } from '@constants';
import { QuizResult } from '@models';

export const suqResults: QuizResult[] = [
  {
    date: '17-Feb-2020',
    location: 'Morningside Tavern',
    players: 6,
    ponderous: { category: 'City', answer: 'Las Vegas', score: 9 },
    rounds: [
      { roundNumber: 1, category: 'Cartoon Heroes', score: 10 },
      { roundNumber: 2, category: CATEGORY_TYPE.scienceAndNature, score: 7, isJoker: false },
      { roundNumber: 3, category: 'Heroes and Villians', score: 7, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.believeItOrNot, score: 6, isJoker: false },
      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 8, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 10, isJoker: true },
      { roundNumber: 7, category: CATEGORY_TYPE.foodAndDrink, score: 9, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.sportAndGames, score: 9, isJoker: false },
    ],
    list: {
      category: CATEGORY_TYPE.tvAndCinema,
      question: 'Any 10 Best Actor or Best Actress Oscar winners from 1980 to 1989',
      score: 0,
    },
    finalScore: 92,
    position: 2,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '24-Feb-2020',
    location: 'Morningside Tavern',
    players: 6,
    ponderous: {
      category: 'Person/Character',
      answer: 'Jim Carrey',
      score: 10,
    },
    rounds: [
      { roundNumber: 1, category: "Don't forget the lyrics", score: 5 },
      {
        roundNumber: 2,
        category: 'Tom, Dick, and Harry',
        score: 6,
        isJoker: false,
      },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 9, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.howMany, score: 8, isJoker: false },
      { roundNumber: 5, category: 'Entertainment', score: 8, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 7, isJoker: true },
      { roundNumber: 7, category: CATEGORY_TYPE.oldStuff, score: 6, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.fourLetterAnswers, score: 8, isJoker: false },
    ],
    list: {
      category: 'Sport',
      question: 'Rugby players to have played in 2 RWC winning teams',
      score: 9,
    },
    finalScore: 92,
    position: 5,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '3-Mar-2020',
    location: 'Horse & Trap',
    players: 6,
    ponderous: { category: 'Person/Character', answer: '', score: 9 },
    rounds: [
      { roundNumber: 1, category: 'Branded', score: 10 },
      { roundNumber: 2, category: CATEGORY_TYPE.oldStuff, score: 5, isJoker: false },
      { roundNumber: 3, category: 'By George', score: 8, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.fiftyFifty, score: 6, isJoker: false },
      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 6, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 9, isJoker: true },
      { roundNumber: 7, category: CATEGORY_TYPE.geography, score: 8, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.tvAndCinema, score: 8, isJoker: true },
    ],
    list: {
      category: 'General',
      question: 'The top 15 currencies strong than the NZ Dollar at present',
      score: 6,
    },
    finalScore: 92,
    position: 3,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '10-Mar-2020',
    location: 'Horse & Trap',
    players: 6,
    ponderous: { category: 'City', answer: '', score: 9 },
    rounds: [
      { roundNumber: 1, category: 'The 2010s', score: 10 },
      { roundNumber: 2, category: CATEGORY_TYPE.sportAndGames, score: 8, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.tvAndCinema, score: 9, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.believeItOrNot, score: 9, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 7, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.alphabetSoup, score: 9, isJoker: true },
      { roundNumber: 7, category: CATEGORY_TYPE.artAndLiterature, score: 8, isJoker: false },
      { roundNumber: 8, category: 'The Long of it', score: 7, isJoker: false },
    ],
    list: {
      category: CATEGORY_TYPE.geography,
      question: 'NZ National Parks',
      score: 4,
    },
    finalScore: 98,
    position: 4,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '9-Jun-2020',
    location: 'Citizen Park',
    players: 6,
    ponderous: { category: 'City', answer: '', score: 10 },
    rounds: [
      { roundNumber: 1, category: 'Who Am I?', score: 8 },
      { roundNumber: 2, category: CATEGORY_TYPE.tvAndCinema, score: 7, isJoker: false },
      { roundNumber: 3, category: 'Brain Teasers', score: 9, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.fiftyFifty, score: 9, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 6, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 8, isJoker: true },
      { roundNumber: 7, category: CATEGORY_TYPE.scienceAndNature, score: 9, isJoker: true },
      { roundNumber: 8, category: CATEGORY_TYPE.sportAndGames, score: 8, isJoker: false },
    ],
    list: {
      category: CATEGORY_TYPE.geography,
      question: 'Any of the Top 15 U.S States by area',
      score: 9,
    },
    finalScore: 100,
    position: 4,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '16-Jun-2020',
    location: 'Citizen Park',
    players: 6,
    ponderous: { category: 'Band', answer: 'Queen', score: 8 },
    rounds: [
      { roundNumber: 1, category: 'Turn Back Time', score: 9 },
      { roundNumber: 2, category: CATEGORY_TYPE.sportAndGames, score: 9, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 9, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.howMany, score: 10, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.tvThemes, score: 9, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 7, isJoker: true },
      { roundNumber: 7, category: CATEGORY_TYPE.foodAndDrink, score: 5, isJoker: false },
      { roundNumber: 8, category: 'Doctors & Nurses', score: 6, isJoker: false },
    ],
    list: {
      category: CATEGORY_TYPE.tvAndCinema,
      question: 'Tom Hanks films 1990-2000',
      score: 10,
    },
    finalScore: 98,
    position: 1,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '23-Jun-2020',
    location: 'Citizen Park',
    players: 6,
    ponderous: { category: 'Person/Character', answer: 'Mario', score: 10 },
    rounds: [
      { roundNumber: 1, category: 'Periodic Table', score: 10 },
      { roundNumber: 2, category: CATEGORY_TYPE.tvAndCinema, score: 10, isJoker: true },
      { roundNumber: 3, category: 'Geez Wayne', score: 7, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.believeItOrNot, score: 10, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 8, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 8, isJoker: true },
      { roundNumber: 7, category: CATEGORY_TYPE.artAndLiterature, score: 7, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.oldStuff, score: 7, isJoker: false },
    ],
    list: {
      category: CATEGORY_TYPE.geography,
      question: "Any NZ's 15 longest rivers",
      score: 6,
    },
    finalScore: 101,
    position: 2,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '30-Jun-2020',
    location: 'Citizen Park',
    players: 6,
    ponderous: { category: '', answer: '', score: 6 },
    rounds: [
      { roundNumber: 1, category: "Art for Art's Sake", score: 10 },
      { roundNumber: 2, category: CATEGORY_TYPE.geography, score: 9, isJoker: true },
      { roundNumber: 3, category: CATEGORY_TYPE.tvAndCinema, score: 8, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.oddOneOut, score: 9, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 5, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.alphabetSoup, score: 9, isJoker: true },
      { roundNumber: 7, category: CATEGORY_TYPE.scienceAndNature, score: 8, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.sportAndGames, score: 5, isJoker: false },
    ],
    list: {
      category: CATEGORY_TYPE.geography,
      question: "Cities which contain any of the world's 20 busiest airports by passenger traffic",
      score: 10,
    },
    finalScore: 97,
    position: 3,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '14-Jul-2020',
    location: 'Citizen Park',
    players: 6,
    ponderous: { category: 'Country', answer: '', score: 10 },
    rounds: [
      { roundNumber: 1, category: 'Famous Landmarks', score: 9 },
      { roundNumber: 2, category: CATEGORY_TYPE.tvAndCinema, score: 9, isJoker: true },
      { roundNumber: 3, category: CATEGORY_TYPE.sportAndGames, score: 7, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.howMany, score: 8, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 6, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 6, isJoker: false },
      { roundNumber: 7, category: 'All Creatures', score: 9, isJoker: true },
      { roundNumber: 8, category: CATEGORY_TYPE.foodAndDrink, score: 7, isJoker: false },
    ],
    list: {
      category: 'Science',
      question: "Periodic elements beginning with 'N' or 'M'",
      score: 9,
    },
    finalScore: 98,
    position: 4,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '22-Jul-2020',
    location: 'The Albert',
    players: 6,
    ponderous: {
      category: 'Person/Character',
      answer: 'Leonardo Da Vinci',
      score: 9,
    },
    rounds: [
      { roundNumber: 1, category: 'Comics', score: 10 },
      { roundNumber: 2, category: CATEGORY_TYPE.geography, score: 9, isJoker: true },
      { roundNumber: 3, category: CATEGORY_TYPE.tvAndCinema, score: 9, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.fiftyFifty, score: 9, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 9, isJoker: false },
      {
        roundNumber: 6,
        category: CATEGORY_TYPE.potLuck,
        score: 9,
        isJoker: false,
      },
      { roundNumber: 7, category: 'Angela, Pamela, Sandra, Rita', score: 8, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.oldStuff, score: 9, isJoker: false },
    ],
    list: {
      category: 'Sport',
      question: 'Top 20 NZ Wicket Takers',
      score: 10,
    },
    finalScore: 109,
    position: 1,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '29-Jul-2020',
    location: 'The Albert',
    players: 6,
    ponderous: {
      category: 'Person/Character',
      answer: 'Harrison Ford',
      score: 9,
    },
    rounds: [
      { roundNumber: 1, category: 'Who Am I?', score: 9 },
      { roundNumber: 2, category: 'Sport', score: 5, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.foodAndDrink, score: 8, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.believeItOrNot, score: 8, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 8, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 7, isJoker: true },
      { roundNumber: 7, category: CATEGORY_TYPE.scienceAndNature, score: 8, isJoker: true },
      { roundNumber: 8, category: CATEGORY_TYPE.artAndLiterature, score: 8, isJoker: false },
    ],
    list: {
      category: '',
      question: '',
      score: 8,
    },
    finalScore: 93,
    position: 3,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '5-Aug-2020',
    location: 'Good Home',
    players: 6,
    ponderous: { category: '', answer: '', score: 10 },
    rounds: [
      { roundNumber: 1, category: 'Who Wrote It?', score: 7 },
      { roundNumber: 2, category: CATEGORY_TYPE.tvAndCinema, score: 8, isJoker: true },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 10, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.fiftyFifty, score: 9, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 5, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 9, isJoker: false },
      { roundNumber: 7, category: 'Royalty', score: 9, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.sportAndGames, score: 7, isJoker: false },
    ],
    list: {
      category: '',
      question: '',
      score: 10,
    },
    finalScore: 102,
    position: 4,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '1-Sep-2020',
    location: 'Citizen Park',
    players: 3,
    ponderous: { category: 'Supergroup', answer: 'The Avengers', score: 9 },
    rounds: [
      { roundNumber: 1, category: 'Who Am I?', score: 8 },
      { roundNumber: 2, category: CATEGORY_TYPE.foodAndDrink, score: 4, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.sportAndGames, score: 5, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.believeItOrNot, score: 5, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.tvThemes, score: 5, isJoker: true },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 8, isJoker: false },
      { roundNumber: 7, category: 'Days & Months', score: 10, isJoker: true },
      { roundNumber: 8, category: CATEGORY_TYPE.scienceAndNature, score: 5, isJoker: false },
    ],
    list: {
      category: CATEGORY_TYPE.geography,
      question: 'All 10 countries that the Danube runs through or borders',
      score: 4,
    },
    finalScore: 78,
    position: 6,
    notes: 'Only Tomos, Graig, David',
    answerSheetId: '',
  },
  {
    date: '8-Sep-2020',
    location: 'Citizen Park',
    players: 6,
    ponderous: { category: 'Object', answer: 'Swiss Army Knife', score: 8 },
    rounds: [
      { roundNumber: 1, category: 'Masked', score: 9 },
      { roundNumber: 2, category: CATEGORY_TYPE.tvAndCinema, score: 6, isJoker: true },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 8, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.howMany, score: 5, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 5, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.alphabetSoup, score: 8, isJoker: true },
      { roundNumber: 7, category: "Kid's Stuff", score: 9, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.sportAndGames, score: 9, isJoker: false },
    ],
    list: {
      category: 'Politics',
      question: 'Leaders for National or Labour since 2000',
      score: 10,
    },
    finalScore: 91,
    position: 5,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '15-Sep-2020',
    location: 'Citizen Park',
    players: 6,
    ponderous: { category: 'Country', answer: '', score: 8 },
    rounds: [
      { roundNumber: 1, category: 'Turn Back Time', score: 6 },
      { roundNumber: 2, category: CATEGORY_TYPE.sportAndGames, score: 8, isJoker: false },
      { roundNumber: 3, category: 'Tom, Dick and Harry', score: 8, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.oddOneOut, score: 10, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 7, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 9, isJoker: true },
      { roundNumber: 7, category: CATEGORY_TYPE.artAndLiterature, score: 9, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.scienceAndNature, score: 10, isJoker: true },
    ],
    list: {
      category: CATEGORY_TYPE.tvAndCinema,
      question: 'Best Actor or Actress Oscar Winners from 2010-2019',
      score: 9,
    },
    finalScore: 103,
    position: 2,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '21-Sep-2020',
    location: 'Morningside Tavern',
    players: 6,
    ponderous: {
      category: 'Person/Character',
      answer: 'Sylvester Stallone',
      score: 8,
    },
    rounds: [
      { roundNumber: 1, category: 'Who Said That?', score: 10 },
      { roundNumber: 2, category: CATEGORY_TYPE.artAndLiterature, score: 9, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 8, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.fiftyFifty, score: 10, isJoker: true },

      { roundNumber: 5, category: CATEGORY_TYPE.tvThemes, score: 8, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 8, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.foodAndDrink, score: 9, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.sportAndGames, score: 9, isJoker: false },
    ],
    list: {
      category: CATEGORY_TYPE.music,
      question: 'Singles that lasted 11 weeks or more on the NZ charts',
      score: 5,
    },
    finalScore: 102,
    position: 3,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '28-Sep-2020',
    location: 'Morningside Tavern',
    players: 6,
    ponderous: { category: '', answer: '', score: 5 },
    rounds: [
      { roundNumber: 1, category: 'Who Am I', score: 9 },
      { roundNumber: 2, category: 'Sport', score: 7, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.scienceAndNature, score: 10, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.yayOrNay, score: 10, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 6, isJoker: false },
      { roundNumber: 6, category: 'Close, But No Cigar', score: 10, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.tvAndCinema, score: 9, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.geography, score: 8, isJoker: true },
    ],
    list: {
      category: CATEGORY_TYPE.geography,
      question: 'Any domestic destination serviced by Air New Zealand',
      score: 10,
    },
    finalScore: 102,
    position: 3,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '13-Oct-2020',
    location: 'Citizen Park',
    players: 6,
    ponderous: { category: 'Number', answer: '21', score: 8 },
    rounds: [
      { roundNumber: 1, category: 'Who Am I', score: 8 },
      { roundNumber: 2, category: CATEGORY_TYPE.geography, score: 7, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.tvAndCinema, score: 9, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.yayOrNay, score: 7, isJoker: true },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 7, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 8, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.fourLetterAnswers, score: 9, isJoker: true },
      { roundNumber: 8, category: CATEGORY_TYPE.sportAndGames, score: 9, isJoker: false },
    ],
    list: {
      category: CATEGORY_TYPE.foodAndDrink,
      question: 'Any 10 of the 14 ingredients in the recipe for Hot Cross Buns in Edmonds Cookery Book',
      score: 9,
    },
    finalScore: 97,
    position: 4,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '20-Oct-2020',
    location: 'Citizen Park',
    players: 6,
    ponderous: { category: '', answer: '', score: 9 },
    rounds: [
      { roundNumber: 1, category: 'Movie Masquerade', score: 10 },
      { roundNumber: 2, category: CATEGORY_TYPE.oldStuff, score: 8, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 8, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.oddOneOut, score: 7, isJoker: true },

      { roundNumber: 5, category: CATEGORY_TYPE.tvThemes, score: 8, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 8, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.scienceAndNature, score: 7, isJoker: true },
      { roundNumber: 8, category: CATEGORY_TYPE.sportAndGames, score: 6, isJoker: false },
    ],
    list: {
      category: CATEGORY_TYPE.music,
      question: 'Any of the NZ number one singles by Rihanna, Black Eyed Peas or UB40',
      score: 7,
    },
    finalScore: 92,
    position: 3,
    notes: 'Lose tie break for second',
    answerSheetId: '',
  },
  {
    date: '27-Oct-2020',
    location: 'Citizen Park',
    players: 6,
    ponderous: { category: 'Country', answer: 'Vietnam', score: 8 },
    rounds: [
      { roundNumber: 1, category: 'Bookworms', score: 8 },
      { roundNumber: 2, category: 'Colours', score: 10, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.sportAndGames, score: 9, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.believeItOrNot, score: 7, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 6, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.alphabetSoup, score: 7, isJoker: true },
      { roundNumber: 7, category: CATEGORY_TYPE.tvAndCinema, score: 10, isJoker: true },
      { roundNumber: 8, category: CATEGORY_TYPE.foodAndDrink, score: 8, isJoker: false },
    ],
    list: {
      category: CATEGORY_TYPE.geography,
      question: 'African countries entirely south of the equator',
      score: 10,
    },
    finalScore: 100,
    position: 3,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '3-Nov-2020',
    location: 'Citizen Park',
    players: 6,
    ponderous: {
      category: 'Person/Character',
      answer: 'Enid Blyton',
      score: 6,
    },
    rounds: [
      { roundNumber: 1, category: 'Sports Stars', score: 7 },
      {
        roundNumber: 2,
        category: CATEGORY_TYPE.fourLetterAnswers,
        score: 9,
        isJoker: true,
      },
      { roundNumber: 3, category: CATEGORY_TYPE.tvAndCinema, score: 9, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.fiftyFifty, score: 9, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 6, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 9, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.geography, score: 8, isJoker: false },
      { roundNumber: 8, category: 'Jack & Jill', score: 8, isJoker: false },
    ],
    list: {
      category: 'Sports',
      question: 'Any of the 20 venues to have hosted the Winter Olympic Games',
      score: 10,
    },
    finalScore: 99,
    position: 3,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '10-Nov-2020',
    location: 'Citizen Park',
    players: 6,
    ponderous: { category: 'Number', answer: '14', score: 6 },
    rounds: [
      { roundNumber: 1, category: 'Mr. & Misses', score: 8 },
      { roundNumber: 2, category: CATEGORY_TYPE.foodAndDrink, score: 6, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.sportAndGames, score: 6, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.yayOrNay, score: 8, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 6, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 7, isJoker: true },
      { roundNumber: 7, category: CATEGORY_TYPE.tvAndCinema, score: 8, isJoker: true },
      { roundNumber: 8, category: CATEGORY_TYPE.geography, score: 9, isJoker: false },
    ],
    list: {
      category: 'Companies',
      question: "Any of the world's 25 most valuable global brands according to Kantar's BRANDZ 2020 list",
      score: 8,
    },
    finalScore: 87,
    position: 10,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '17-Nov-2020',
    location: 'Citizen Park',
    players: 6,
    ponderous: { category: 'City', answer: 'Naples', score: 8 },
    rounds: [
      { roundNumber: 1, category: 'R.I.P (Dead People)', score: 8 },
      { roundNumber: 2, category: CATEGORY_TYPE.tvAndCinema, score: 9, isJoker: true },
      { roundNumber: 3, category: 'Sport', score: 6, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.oddOneOut, score: 7, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 10, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.alphabetSoup, score: 6, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.artAndLiterature, score: 7, isJoker: false },
      { roundNumber: 8, category: 'Planes, Trains & Automobiles', score: 8, isJoker: true },
    ],
    list: {
      category: CATEGORY_TYPE.geography,
      question: "Any U.S State that ends with the letter 'A'",
      score: 10,
    },
    finalScore: 96,
    position: 3,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '24-Nov-2020',
    location: 'Citizen Park',
    players: 6,
    ponderous: { category: 'Animal', answer: 'Mussel', score: 10 },
    rounds: [
      { roundNumber: 1, category: 'Sporting Celebrations', score: 10 },
      { roundNumber: 2, category: CATEGORY_TYPE.foodAndDrink, score: 9, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 8, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.howMany, score: 7, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 6, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 8, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.fourLetterAnswers, score: 9, isJoker: true },
      { roundNumber: 8, category: CATEGORY_TYPE.tvAndCinema, score: 7, isJoker: true },
    ],
    list: {
      category: 'Sport',
      question: "Any men's Rugby World Cup winning Captains or Head Coaches (17)",
      score: 10,
    },
    finalScore: 100,
    position: 3,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '29-Nov-2020',
    location: 'The Hangar',
    players: 8,
    ponderous: { category: 'None', answer: 'None', score: 0 },
    rounds: [
      { roundNumber: 1, category: 'History', score: 8 },
      { roundNumber: 2, category: CATEGORY_TYPE.tvAndCinema, score: 8, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 8, isJoker: false },
      { roundNumber: 4, category: 'Sport', score: 5, isJoker: false },

      { roundNumber: 5, category: 'Mystery Round', score: 6, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 9, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.scienceAndNature, score: 10, isJoker: false },
      { roundNumber: 8, category: 'Art & Lit & Stage', score: 6, isJoker: false },
    ],
    list: {
      category: CATEGORY_TYPE.music,
      question: "Any men's Rugby World Cup winning Captains or Head Coaches (17)",
      score: 10,
    },
    finalScore: 70,
    position: 9,
    notes: 'Pub Quiz Champs 2020',
    answerSheetId: '',
  },
  {
    date: '1-Dec-2020',
    location: 'Citizen Park',
    players: 8,
    ponderous: {
      category: 'Person/Character',
      answer: 'Donald Trump',
      score: 10,
    },
    rounds: [
      { roundNumber: 1, category: "Art for Art's Sake", score: 10 },
      { roundNumber: 2, category: CATEGORY_TYPE.sportAndGames, score: 8, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 8, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.yayOrNay, score: 9, isJoker: false },

      { roundNumber: 5, category: 'Movie Themes', score: 10, isJoker: true },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 10, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.oldStuff, score: 8, isJoker: false },
      { roundNumber: 8, category: 'Double Trouble', score: 8, isJoker: false },
    ],
    list: {
      category: CATEGORY_TYPE.music,
      question: 'Winners of American Idol, Aus Idol, or NZ Idol (28)',
      score: 8,
    },
    finalScore: 107,
    position: 2,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '8-Dec-2020',
    location: 'Citizen Park',
    players: 7,
    ponderous: { category: 'Year', answer: '1982', score: 10 },
    rounds: [
      { roundNumber: 1, category: 'Christmas Babies', score: 7 },
      { roundNumber: 2, category: CATEGORY_TYPE.sportAndGames, score: 9, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.artAndLiterature, score: 8, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.fiftyFifty, score: 8, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 7, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.alphabetSoup, score: 9, isJoker: true },
      { roundNumber: 7, category: CATEGORY_TYPE.foodAndDrink, score: 6, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.tvAndCinema, score: 9, isJoker: true },
    ],
    list: {
      category: CATEGORY_TYPE.geography,
      question: 'Countries that do not recognise Christmas as a public holiday (34)',
      score: 9,
    },
    finalScore: 100,
    position: 4,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '15-Dec-2020',
    location: 'Citizen Park',
    players: 7,
    ponderous: {
      category: 'Person/Character',
      answer: 'Rudolph the Red Nosed Reindeer',
      score: 8,
    },
    rounds: [
      { roundNumber: 1, category: 'Christmas Farewells', score: 8 },
      { roundNumber: 2, category: CATEGORY_TYPE.tvAndCinema, score: 9, isJoker: true },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 10, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.howMany, score: 10, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 5, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 9, isJoker: false },
      { roundNumber: 7, category: 'Goodies & Baddies', score: 9, isJoker: true },
      { roundNumber: 8, category: CATEGORY_TYPE.sportAndGames, score: 10, isJoker: false },
    ],
    list: {
      category: CATEGORY_TYPE.foodAndDrink,
      question: 'Ingredients in Ginger People according to Edmonds Cookery Book (10)',
      score: 7,
    },
    finalScore: 103,
    position: 3,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '7-Jan-2021',
    location: 'Citizen Park',
    players: 6,
    ponderous: { category: 'Year', answer: '2003', score: 6 },
    rounds: [
      { roundNumber: 1, category: 'Famous Landmarks', score: 9 },
      { roundNumber: 2, category: CATEGORY_TYPE.tvAndCinema, score: 8, isJoker: true },
      {
        roundNumber: 3,
        category: "You're a right Charlie",
        score: 8,
        isJoker: false,
      },
      { roundNumber: 4, category: CATEGORY_TYPE.howMany, score: 7, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 8, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 10, isJoker: true },
      { roundNumber: 7, category: CATEGORY_TYPE.foodAndDrink, score: 9, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.sportAndGames, score: 5, isJoker: false },
    ],
    list: {
      category: CATEGORY_TYPE.geography,
      question: 'Pre Euro currencies',
      score: 5,
    },
    finalScore: 93,
    position: 5,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '19-Jan-2021',
    location: 'Citizen Park',
    players: 7,
    ponderous: { category: 'Country', answer: 'Portugal', score: 10 },
    rounds: [
      { roundNumber: 1, category: 'Noughties Kids', score: 8 },
      { roundNumber: 2, category: CATEGORY_TYPE.tvAndCinema, score: 10, isJoker: true },
      { roundNumber: 3, category: 'It Takes Two', score: 8, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.oddOneOut, score: 8, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 7, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 8, isJoker: true },
      { roundNumber: 7, category: CATEGORY_TYPE.oldStuff, score: 9, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.sportAndGames, score: 9, isJoker: false },
    ],
    list: {
      category: CATEGORY_TYPE.geography,
      question: "Capital Cities Starting With 'M'",
      score: 10,
    },
    finalScore: 105,
    position: 1,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '27-Jan-2021',
    location: 'The Albert',
    players: 6,
    ponderous: { category: 'Animal', answer: 'Donkey', score: 8 },
    rounds: [
      { roundNumber: 1, category: 'Acronyms', score: 9 },
      { roundNumber: 2, category: CATEGORY_TYPE.sportAndGames, score: 7, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 18, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.yayOrNay, score: 7, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 7, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 9, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.artAndLiterature, score: 8, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.tvAndCinema, score: 8, isJoker: true },
    ],
    list: {
      category: 'Science',
      question: 'The 10 Elements with the fewest letters',
      score: 10,
    },
    finalScore: 99,
    position: 1,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '2-Feb-2021',
    location: 'Citizen Park',
    players: 8,
    ponderous: {
      category: 'Person/Character',
      answer: 'Andrew Llyod Webber',
      score: 9,
    },
    rounds: [
      { roundNumber: 1, category: 'Video Games', score: 10 },
      { roundNumber: 2, category: CATEGORY_TYPE.geography, score: 9, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.tvAndCinema, score: 10, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.believeItOrNot, score: 6, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 7, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.alphabetSoup, score: 7, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.oldStuff, score: 9, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.fourLetterAnswers, score: 8, isJoker: true },
    ],
    list: {
      category: 'Sport',
      question: 'The 10 different endings for football clubs besides United and City',
      score: 9,
    },
    finalScore: 102,
    position: 2,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '10-Feb-2021',
    location: 'Good Home',
    players: 8,
    ponderous: { category: CATEGORY_TYPE.geography, answer: 'Ecuador', score: 8 },
    rounds: [
      { roundNumber: 1, category: 'At the Movies', score: 10 },
      { roundNumber: 2, category: 'Sport & Rec', score: 9, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.oldStuff, score: 8, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.oddOneOut, score: 8, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.scienceAndNature, score: 10, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 7, isJoker: true },
      { roundNumber: 7, category: CATEGORY_TYPE.foodAndDrink, score: 8, isJoker: false },
      { roundNumber: 8, category: 'It Takes Two to Tango', score: 9, isJoker: true },
    ],
    list: {
      category: CATEGORY_TYPE.geography,
      question: 'Largest Islands by Area (20)',
      score: 6,
    },
    finalScore: 99,
    position: 2,
    notes: 'Lose tie Break for first',
    answerSheetId: '',
  },
  {
    date: '23-Feb-2021',
    location: 'Citizen Park',
    players: 8,
    ponderous: { category: CATEGORY_TYPE.geography, answer: 'Picton', score: 8 },
    rounds: [
      { roundNumber: 1, category: 'Spelling Bee', score: 8 },
      { roundNumber: 2, category: CATEGORY_TYPE.tvAndCinema, score: 8, isJoker: true },
      { roundNumber: 3, category: CATEGORY_TYPE.scienceAndNature, score: 9, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.oddOneOut, score: 3, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 9, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 8, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.oldStuff, score: 7, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.sportAndGames, score: 7, isJoker: false },
    ],
    list: {
      category: CATEGORY_TYPE.geography,
      question: 'Top 2-5 cities by population of France, Germany, Spain, Italy (12)',
      score: 9,
    },
    finalScore: 93,
    position: 7,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '10-Mar-2021',
    location: 'Good Home',
    players: 5,
    ponderous: {
      category: 'Person/Character',
      answer: 'Richard Branson',
      score: 10,
    },
    rounds: [
      { roundNumber: 1, category: 'Branded', score: 9 },
      { roundNumber: 2, category: CATEGORY_TYPE.tvAndCinema, score: 9, isJoker: true },
      { roundNumber: 3, category: CATEGORY_TYPE.oldStuff, score: 5, isJoker: false },
      { roundNumber: 4, category: 'What Happened First', score: 6, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 9, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 8, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.alphabetSoup, score: 8, isJoker: true },
      { roundNumber: 8, category: CATEGORY_TYPE.sportAndGames, score: 9, isJoker: false },
    ],
    list: {
      category: CATEGORY_TYPE.geography,
      question: 'Countries where Spanish is the official language',
      score: 10,
    },
    finalScore: 100,
    position: 3,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '16-Mar-2021',
    location: 'Citizen Park',
    players: 7,
    ponderous: { category: 'Year', answer: '1998', score: 10 },
    rounds: [
      { roundNumber: 1, category: 'Olympic Games', score: 10 },
      { roundNumber: 2, category: CATEGORY_TYPE.geography, score: 9, isJoker: true },
      { roundNumber: 3, category: "Let's be Frank", score: 10, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.fiftyFifty, score: 7, isJoker: false },
      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 7, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 9, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.scienceAndNature, score: 9, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.tvAndCinema, score: 10, isJoker: true },
    ],
    list: {
      category: 'General',
      question: 'Any of the 12 Chinese Zodiac Animals',
      score: 10,
    },
    finalScore: 110,
    position: 2,
    notes: "Lose to Steven Macdonald's Bagpipes (112)",
    answerSheetId: '',
  },
  {
    date: '23-Mar-2021',
    location: 'Citizen Park',
    players: 6,
    ponderous: { category: 'Number', answer: '13', score: 10 },
    rounds: [
      { roundNumber: 1, category: 'Turn Back Time', score: 7 },
      { roundNumber: 2, category: CATEGORY_TYPE.sportAndGames, score: 8, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.scienceAndNature, score: 8, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.howMany, score: 8, isJoker: false },
      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 9, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 9, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.geography, score: 9, isJoker: true },
      { roundNumber: 8, category: CATEGORY_TYPE.tvAndCinema, score: 9, isJoker: true },
    ],
    list: {
      category: 'General',
      question: "Any of the Greek Letters that end in 'A'",
      score: 10,
    },
    finalScore: 105,
    position: 1,
    notes: '',
    answerSheetId: '',
  },
  {
    date: '30-Mar-2021',
    location: 'Citizen Park',
    players: 6,
    ponderous: { category: 'Person/Character', answer: 'A.A Milne', score: 8 },
    rounds: [
      { roundNumber: 1, category: 'Name That Song', score: 9 },
      { roundNumber: 2, category: CATEGORY_TYPE.tvAndCinema, score: 5, isJoker: true },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 8, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.fiftyFifty, score: 8, isJoker: false },
      { roundNumber: 5, category: 'Sam I Am', score: 9, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 9, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.alphabetSoup, score: 7, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.sportAndGames, score: 8, isJoker: false },
    ],
    list: {
      category: 'Sport',
      question: 'Warriors with more than 100 games',
      score: 7,
    },
    finalScore: 91,
    position: 6,
    notes: 'Missing Tomos',
    answerSheetId: '',
  },
  {
    date: '6-Apr-2021',
    location: 'Citizen Park',
    players: 7,
    ponderous: { category: 'Country', answer: 'Norway', score: 10 },
    rounds: [
      { roundNumber: 1, category: 'Flag That', score: 9 },
      { roundNumber: 2, category: CATEGORY_TYPE.tvAndCinema, score: 8, isJoker: true },
      { roundNumber: 3, category: CATEGORY_TYPE.foodAndDrink, score: 10, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.yayOrNay, score: 9, isJoker: false },
      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 6, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 9, isJoker: true },
      { roundNumber: 7, category: CATEGORY_TYPE.artAndLiterature, score: 4, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.sportAndGames, score: 10, isJoker: false },
    ],
    list: {
      category: 'Games',
      question: "Any of the 12 2 letter words allowed in scrabble with 'M' in them",
      score: 10,
    },
    finalScore: 102,
    position: 3,
    notes: 'Lose tie break for second',
    answerSheetId: '',
  },
  {
    date: '13-Apr-2021',
    location: 'Citizen Park',
    players: 7,
    ponderous: { category: 'Year', answer: '1986', score: 10 },
    rounds: [
      { roundNumber: 1, category: 'Heels and Faces', score: 10 },
      { roundNumber: 2, category: 'Famous People', score: 9, isJoker: true },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 7, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.believeItOrNot, score: 8, isJoker: false },
      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 9, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 9, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.artAndLiterature, score: 9, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.tvAndCinema, score: 9, isJoker: false },
    ],
    list: {
      category: 'Sport',
      question: 'The 10 countries to have played the most matchees in the RWC tournament matches.',
      score: 10,
    },
    finalScore: 106,
    position: 2,
    notes: 'Lose tie break for first',
    answerSheetId: '',
  },
  {
    date: '14-Apr-2021',
    location: 'Good Home',
    players: 6,
    ponderous: { category: 'City', answer: 'Istanbul', score: 9 },
    rounds: [
      { roundNumber: 1, category: 'At the flicks', score: 10 },
      {
        roundNumber: 2,
        category: '3 Letter Answers',
        score: 8,
        isJoker: true,
      },
      { roundNumber: 3, category: CATEGORY_TYPE.oldStuff, score: 8, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.howMany, score: 5, isJoker: false },
      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 10, isJoker: true },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 6, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.foodAndDrink, score: 9, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.sportAndGames, score: 8, isJoker: false },
    ],
    list: {
      category: CATEGORY_TYPE.tvAndCinema,
      question: 'The main actors in Friends and Seinfeld',
      score: 10,
    },
    finalScore: 101,
    position: 4,
    notes: 'Example only',
    answerSheetId: '',
  },
  {
    date: '19-Apr-2021',
    location: 'Morningside Tavern',
    players: 5,
    ponderous: { category: 'Book', answer: 'Pride and Prejudice', score: 8 },
    rounds: [
      { roundNumber: 1, category: 'Famous Couples', score: 8 },
      { roundNumber: 2, category: CATEGORY_TYPE.foodAndDrink, score: 7, isJoker: false },
      { roundNumber: 3, category: 'Sport', score: 9, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.oddOneOut, score: 9, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 4, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 8, isJoker: false },
      { roundNumber: 7, category: 'All Creatures', score: 6, isJoker: true },
      { roundNumber: 8, category: CATEGORY_TYPE.tvAndCinema, score: 6, isJoker: true },
    ],
    list: {
      category: CATEGORY_TYPE.geography,
      question: 'Countries that use the Euro as its main currency',
      score: 10,
    },
    finalScore: 87,
    position: 7,
    notes: 'Half Unanswered Questions" - Missing Tomos and Nick',
    answerSheetId: '',
  },
  {
    date: '27-Apr-2021',
    location: 'Citizen Park',
    players: 6,
    ponderous: { category: 'Sports Team', answer: 'Dallas Cowboys', score: 9 },
    rounds: [
      { roundNumber: 1, category: 'Wingardium Leviosa', score: 10 },
      { roundNumber: 2, category: CATEGORY_TYPE.foodAndDrink, score: 8, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 9, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.yayOrNay, score: 10, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 9, isJoker: false },
      {
        roundNumber: 6,
        category: CATEGORY_TYPE.potLuck,
        score: 10,
        isJoker: true,
      },
      { roundNumber: 7, category: 'Mary, Kate and Ashley', score: 9, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.tvAndCinema, score: 8, isJoker: false },
    ],
    list: {
      category: CATEGORY_TYPE.tvAndCinema,
      question: 'Any of the top 20 longest running broadway shows',
      score: 6,
    },
    finalScore: 107,
    position: 1,
    notes: 'Win tie break for first',
    answerSheetId: '',
  },
  {
    date: '11-May-2021',
    location: 'Citizen Park',
    players: 7,
    ponderous: { category: 'Year', answer: '2004', score: 8 },
    rounds: [
      { roundNumber: 1, category: 'Fight Night', score: 7 },
      { roundNumber: 2, category: 'Just Games', score: 10, isJoker: true },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 7, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.believeItOrNot, score: 10, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 6, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 7, isJoker: true },
      { roundNumber: 7, category: 'Fashion', score: 8, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.tvAndCinema, score: 8, isJoker: false },
    ],
    list: {
      category: 'Sport',
      question: 'Any of the 12 traitorous football clubs that attempted to form the super league.',
      score: 10,
    },
    finalScore: 98,
    position: 3,
    notes: 'Winning score 101',
    answerSheetId: '',
  },
  {
    date: '18-May-2021',
    location: 'Citizen Park',
    players: 8,
    ponderous: {
      category: 'Person/Character',
      answer: 'Hannibal Lector',
      score: 8,
    },
    rounds: [
      { roundNumber: 1, category: 'Numbers', score: 8 },
      { roundNumber: 2, category: CATEGORY_TYPE.sportAndGames, score: 10, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 9, isJoker: false },
      { roundNumber: 4, category: 'Double Trouble', score: 10, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 7, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 8, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.scienceAndNature, score: 9, isJoker: true },
      { roundNumber: 8, category: CATEGORY_TYPE.tvAndCinema, score: 7, isJoker: true },
    ],
    list: {
      category: 'Politics',
      question: 'Any of the former living prime ministers of NZ/AUS',
      score: 10,
    },
    finalScore: 102,
    position: 1,
    notes: '2nd place 99. First team to get ponderous. Thanks Will',
    answerSheetId: '',
  },
  {
    date: '25-May-2021',
    location: 'Citizen Park',
    players: 8,
    ponderous: { category: 'City', answer: 'Belfast', score: 8 },
    rounds: [
      { roundNumber: 1, category: 'Branded', score: 9 },
      { roundNumber: 2, category: CATEGORY_TYPE.sportAndGames, score: 7, isJoker: false },
      { roundNumber: 3, category: 'Who Dunnit?', score: 7, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.yayOrNay, score: 6, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 8, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 8, isJoker: true },
      { roundNumber: 7, category: CATEGORY_TYPE.foodAndDrink, score: 10, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.tvAndCinema, score: 10, isJoker: true },
    ],
    list: {
      category: 'General',
      question: 'Any of the 15 winners of NZ bird of the year',
      score: 7,
    },
    finalScore: 98,
    position: 2,
    notes: 'Lose tie break for first. 88 AB wins with Henry.',
    answerSheetId: '',
  },
  {
    date: '1-Jun-2021',
    location: 'Citizen Park',
    players: 9,
    ponderous: { category: 'Year', answer: '1971', score: 10 },
    rounds: [
      { roundNumber: 1, category: 'Setlists', score: 10 },
      {
        roundNumber: 2,
        category: 'Planes, Trains and Automobiles',
        score: 9,
        isJoker: false,
      },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 7, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.howMany, score: 6, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.tvAndCinema, score: 9, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 10, isJoker: false },
      { roundNumber: 7, category: 'Jack and Diane', score: 7, isJoker: true },
      { roundNumber: 8, category: 'Sport', score: 9, isJoker: false },
    ],
    list: {
      category: 'General',
      question: 'Any of Queen Elizabeth II grand children or great grand children (not Harry or Will and their children)',
      score: 6,
    },
    finalScore: 97,
    position: 3,
    notes: 'First and second: 104, 103',
    answerSheetId: '',
  },
  {
    date: '8-Jun-2021',
    location: 'Citizen Park',
    players: 10,
    ponderous: { category: 'Object', answer: 'Umbrella', score: 9 },
    rounds: [
      { roundNumber: 1, category: 'Who Am I', score: 9 },
      { roundNumber: 2, category: 'Colours', score: 9, isJoker: true },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 9, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.fiftyFifty, score: 10, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 8, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 10, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.foodAndDrink, score: 7, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.tvAndCinema, score: 10, isJoker: true },
    ],
    list: {
      category: 'Sport',
      question: 'Any of the Snooker or Darts World Champions',
      score: 5,
    },
    finalScore: 105,
    position: 2,
    notes: 'With Bex! Winning score 111',
    answerSheetId: '',
  },
  {
    date: '15-Jun-2021',
    location: 'Citizen Park',
    players: 7,
    ponderous: { category: 'Number', answer: '20', score: 10 },
    rounds: [
      { roundNumber: 1, category: '2000s Timeline', score: 8 },
      { roundNumber: 2, category: CATEGORY_TYPE.artAndLiterature, score: 7, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 10, isJoker: false },
      { roundNumber: 4, category: 'Silver and Gold', score: 10, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 7, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 8, isJoker: true },
      { roundNumber: 7, category: CATEGORY_TYPE.oldStuff, score: 6, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.tvAndCinema, score: 9, isJoker: true },
    ],
    list: {
      category: 'Sport',
      question: "Any of the 20 highest capped All Blacks that haven't won a world cup",
      score: 9,
    },
    finalScore: 101,
    position: 3,
    notes: 'Missing CA and Dave. Out of Africa :(',
    answerSheetId: '',
  },
  {
    date: '22-Jun-2021',
    location: 'Citizen Park',
    players: 6,
    ponderous: {
      category: 'Person/Character',
      answer: 'David Beckham',
      score: 10,
    },
    rounds: [
      { roundNumber: 1, category: 'Where Am I', score: 8 },
      { roundNumber: 2, category: 'Nicknames', score: 9, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.scienceAndNature, score: 9, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.believeItOrNot, score: 9, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 9, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 8, isJoker: false },
      { roundNumber: 7, category: 'It Takes Two', score: 8, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.tvAndCinema, score: 9, isJoker: true },
    ],
    list: {
      category: CATEGORY_TYPE.geography,
      question: '10 US States in reverse alphabetical order',
      score: 10,
    },
    finalScore: 107,
    position: 2,
    notes: 'Lose tie break for first. No Nick or Graig',
    answerSheetId: '',
  },
  {
    date: '29-Jun-2021',
    location: 'Citizen Park',
    players: 9,
    ponderous: { category: 'Island', answer: 'Santorini', score: 8 },
    rounds: [
      { roundNumber: 1, category: 'Gotta Be the Shoes', score: 10 },
      { roundNumber: 2, category: CATEGORY_TYPE.sportAndGames, score: 7, isJoker: true },
      { roundNumber: 3, category: CATEGORY_TYPE.foodAndDrink, score: 8, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.fiftyFifty, score: 8, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 8, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.oldStuff, score: 8, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.alphabetSoup, score: 9, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.tvAndCinema, score: 9, isJoker: true },
    ],
    list: {
      category: CATEGORY_TYPE.geography,
      question: 'Any of the 20 countries with the longest rail networks',
      score: 10,
    },
    finalScore: 101,
    position: 3,
    notes: 'Win tie break for third.',
    answerSheetId: '',
  },
  {
    date: '6-Jul-2021',
    location: 'Citizen Park',
    players: 7,
    ponderous: { category: 'Animal', answer: 'Wolf', score: 9 },
    rounds: [
      { roundNumber: 1, category: 'Chocoholics', score: 8 },
      { roundNumber: 2, category: CATEGORY_TYPE.artAndLiterature, score: 7, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 6, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.howMany, score: 8, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 9, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 8, isJoker: true },
      { roundNumber: 7, category: 'Kings & Queens', score: 9, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.tvAndCinema, score: 10, isJoker: true },
    ],
    list: {
      category: 'Sport',
      question: 'Any of the NZ Olympic flag carriers',
      score: 5,
    },
    finalScore: 97,
    position: 4,
    notes: 'No Nick. Winning score 101',
    answerSheetId: '',
  },
  {
    date: '13-Jul-2021',
    location: 'Citizen Park',
    players: 6,
    ponderous: { category: 'Book', answer: 'Da Vinci Code', score: 10 },
    rounds: [
      { roundNumber: 1, category: 'Acronyms', score: 10 },
      { roundNumber: 2, category: CATEGORY_TYPE.foodAndDrink, score: 8, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 10, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.fiftyFifty, score: 7, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 5, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 6, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.tvAndCinema, score: 8, isJoker: true },
      { roundNumber: 8, category: CATEGORY_TYPE.sportAndGames, score: 8, isJoker: false },
    ],
    list: {
      category: 'Politics',
      question: 'Any of the last 14 British Prime Ministers',
      score: 10,
    },
    finalScore: 100,
    position: 3,
    notes: 'No David. Winning score 106',
    answerSheetId: '',
  },
  {
    date: '20-Jul-2021',
    location: 'Citizen Park',
    players: 8,
    ponderous: { category: 'Movie', answer: 'Die Hard', score: 9 },
    rounds: [
      { roundNumber: 1, category: 'Mind Stretches', score: 10 },
      { roundNumber: 2, category: CATEGORY_TYPE.sportAndGames, score: 8, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 7, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.fiftyFifty, score: 6, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 8, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 9, isJoker: true },
      { roundNumber: 7, category: 'Double Ups', score: 8, isJoker: false },
      { roundNumber: 8, category: 'On The Box', score: 8, isJoker: false },
    ],
    list: {
      category: 'General',
      question: 'Any of the top 20 most used words in the Bible',
      score: 8,
    },
    finalScore: 97,
    position: 4,
    notes: 'First time with Guy! Winning score 101',
    answerSheetId: '',
  },
  {
    date: '27-Jul-2021',
    location: 'Citizen Park',
    players: 9,
    ponderous: { category: CATEGORY_TYPE.geography, answer: 'Madagascar', score: 10 },
    rounds: [
      { roundNumber: 1, category: 'Fill in the Gaps', score: 10 },
      { roundNumber: 2, category: CATEGORY_TYPE.tvAndCinema, score: 10, isJoker: true },
      { roundNumber: 3, category: CATEGORY_TYPE.sportAndGames, score: 10, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.howMany, score: 10, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 7, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 8, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.oldStuff, score: 7, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.foodAndDrink, score: 9, isJoker: true },
    ],
    list: {
      category: CATEGORY_TYPE.geography,
      question: 'The 10 largest countries by area in South Americaa',
      score: 10,
    },
    finalScore: 110,
    position: 1,
    notes: 'Second place 104.',
    answerSheetId: '',
  },
  {
    date: '3-Aug-2021',
    location: 'Citizen Park',
    players: 7,
    ponderous: { category: 'Year', answer: '1984', score: 10 },
    rounds: [
      { roundNumber: 1, category: 'Spelling Bee', score: 9 },
      { roundNumber: 2, category: CATEGORY_TYPE.sportAndGames, score: 9, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 9, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.believeItOrNot, score: 9, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 8, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 7, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.foodAndDrink, score: 10, isJoker: true },
      { roundNumber: 8, category: CATEGORY_TYPE.tvAndCinema, score: 10, isJoker: true },
    ],
    list: {
      category: CATEGORY_TYPE.foodAndDrink,
      question: 'Any of the 11 ingredients in an upside down pudding',
      score: 7,
    },
    finalScore: 108,
    position: 1,
    notes: 'Second place 104.',
    answerSheetId: '',
  },
  {
    date: '10-Aug-2021',
    location: 'Citizen Park',
    players: 11,
    ponderous: { category: 'Person/Character', answer: 'Tony Hawk', score: 9 },
    rounds: [
      { roundNumber: 1, category: 'Who Wrote It?', score: 8 },
      { roundNumber: 2, category: CATEGORY_TYPE.tvAndCinema, score: 9, isJoker: true },
      { roundNumber: 3, category: CATEGORY_TYPE.scienceAndNature, score: 7, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.yayOrNay, score: 9, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 6, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 8, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.geography, score: 10, isJoker: false },
      { roundNumber: 8, category: 'Sara(h) Smile', score: 8, isJoker: false },
    ],
    list: {
      category: 'Sport',
      question: 'Any of the 26 winners of a grand prix since 2000',
      score: 10,
    },
    finalScore: 100,
    position: 4,
    notes: 'No David. Winning score 103.',
    answerSheetId: '',
  },
  {
    date: '18-Jan-2022',
    location: 'Citizen Park',
    players: 5,
    ponderous: {
      category: 'Person/Character',
      answer: 'Lewis Hamilton',
      score: 10,
    },
    rounds: [
      { roundNumber: 1, category: 'What Did They Make?', score: 8 },
      { roundNumber: 2, category: CATEGORY_TYPE.tvAndCinema, score: 9, isJoker: true },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 7, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.howMany, score: 8, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 4, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 6, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.artAndLiterature, score: 4, isJoker: false },
      { roundNumber: 8, category: 'Randy & Mandy', score: 9, isJoker: true },
    ],
    list: {
      category: 'Games',
      question: 'Any of the main characters of 1992 Mario Kart or 1999 Crash Team Racing',
      score: 9,
    },
    finalScore: 92,
    position: 6,
    notes: 'No David, Sadiq, Nick.',
    answerSheetId: '',
  },
  {
    date: '25-Jan-2022',
    location: 'Citizen Park',
    players: 6,
    ponderous: {
      category: 'Person/Character',
      answer: 'Lisa Simpson',
      score: 6,
    },
    rounds: [
      { roundNumber: 1, category: 'Surname Required', score: 5 },
      { roundNumber: 2, category: CATEGORY_TYPE.sportAndGames, score: 5, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.scienceAndNature, score: 6, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.yayOrNay, score: 5, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 7, isJoker: false },
      {
        roundNumber: 6,
        category: CATEGORY_TYPE.alphabetSoup,
        score: 10,
        isJoker: true,
      },
      { roundNumber: 7, category: 'Myths, Legends and Spooky', score: 8, isJoker: true },
      { roundNumber: 8, category: CATEGORY_TYPE.geography, score: 8, isJoker: false },
    ],
    list: {
      category: 'Movies',
      question: 'Any feature films by Jane Campion or Taika Waititi',
      score: 8,
    },
    finalScore: 86,
    position: 5,
    notes: 'Tomos, Lynette, Toni, Sadiq',
    answerSheetId: '1189NV',
  },
  {
    date: '1-Feb-2022',
    location: 'Citizen Park',
    players: 7,
    ponderous: { category: 'Event', answer: 'Glastonbury', score: 10 },
    rounds: [
      { roundNumber: 1, category: 'Missing Members', score: 8 },
      { roundNumber: 2, category: CATEGORY_TYPE.geography, score: 8, isJoker: false },
      { roundNumber: 3, category: 'Amazing Grace', score: 6, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.oddOneOut, score: 9, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.tvAndCinema, score: 8, isJoker: true },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 9, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.sportAndGames, score: 8, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.oldStuff, score: 6, isJoker: false },
    ],
    list: {
      category: 'General',
      question: 'Any of NZs Prisons',
      score: 4,
    },
    finalScore: 90,
    position: 4,
    notes: 'No Nick.',
    answerSheetId: '',
  },
  {
    date: '8-Feb-2022',
    location: 'Citizen Park',
    players: 8,
    ponderous: {
      category: 'Person/Character',
      answer: 'Salvador Dali',
      score: 6,
    },
    rounds: [
      { roundNumber: 1, category: 'Capitals', score: 10 },
      { roundNumber: 2, category: CATEGORY_TYPE.foodAndDrink, score: 8, isJoker: true },
      { roundNumber: 3, category: CATEGORY_TYPE.sportAndGames, score: 8, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.fiftyFifty, score: 7, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 7, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 9, isJoker: false },
      { roundNumber: 7, category: 'All Apologies', score: 10, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.tvAndCinema, score: 7, isJoker: true },
    ],
    list: {
      category: CATEGORY_TYPE.geography,
      question: 'Any 10 of 12 largest regions in NZ',
      score: 9,
    },
    finalScore: 96,
    position: 3,
    notes: 'Back to full strength',
    answerSheetId: '',
  },
  {
    date: '15-Feb-2022',
    location: 'Citizen Park',
    players: 7,
    ponderous: { category: 'Company', answer: 'McDonalds', score: 10 },
    rounds: [
      { roundNumber: 1, category: 'Branded', score: 9 },
      { roundNumber: 2, category: CATEGORY_TYPE.tvAndCinema, score: 8, isJoker: true },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 9, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.believeItOrNot, score: 10, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 9, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 9, isJoker: false },
      { roundNumber: 7, category: 'Brain Teasers', score: 9, isJoker: true },
      { roundNumber: 8, category: CATEGORY_TYPE.scienceAndNature, score: 9, isJoker: false },
    ],
    list: {
      category: 'Sports',
      question: 'Any 10 of 15 sports at the winter Olympics',
      score: 10,
    },
    finalScore: 109,
    position: 2,
    notes: 'No Nick. Winning score 110. Honesty!',
    answerSheetId: '',
  },
  {
    date: '22-Feb-2022',
    location: 'Citizen Park',
    players: 10,
    ponderous: { category: 'Story', answer: 'Romeo & Juliet', score: 10 },
    rounds: [
      { roundNumber: 1, category: 'Soft and Hard Drinks', score: 10 },
      { roundNumber: 2, category: 'At the Flicks', score: 10, isJoker: true },
      { roundNumber: 3, category: CATEGORY_TYPE.sportAndGames, score: 9, isJoker: false },
      { roundNumber: 4, category: 'Paper Scissors Rock', score: 9, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 10, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 7, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.geography, score: 9, isJoker: true },
      { roundNumber: 8, category: 'Planes, Trains & Automobiles', score: 8, isJoker: false },
    ],
    list: {
      category: 'Tv',
      question: "Any 10 of the 22 presenters of Tv One's Breakfast",
      score: 10,
    },
    finalScore: 111,
    position: 1,
    notes: 'Full team! Best ever score!',
    answerSheetId: '',
  },
  {
    date: '1-Mar-2022',
    location: 'Citizen Park',
    players: 8,
    ponderous: { category: 'Band', answer: 'Rolling Stones', score: 8 },
    rounds: [
      { roundNumber: 1, category: 'Name That Voice', score: 8 },
      { roundNumber: 2, category: CATEGORY_TYPE.geography, score: 6, isJoker: true },
      { roundNumber: 3, category: CATEGORY_TYPE.foodAndDrink, score: 10, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.howMany, score: 10, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.tvThemes, score: 9, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.alphabetSoup, score: 9, isJoker: true },
      { roundNumber: 7, category: CATEGORY_TYPE.sportAndGames, score: 8, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.oldStuff, score: 9, isJoker: false },
    ],
    list: {
      category: 'Movies',
      question: 'Any 10 movies that Keanue Reeves appeared in between 1989 and 2003',
      score: 9,
    },
    finalScore: 101,
    position: 2,
    notes: 'Missing Graig, Dave, Guy',
    answerSheetId: '',
  },
  {
    date: '8-Mar-2022',
    location: 'Citizen Park',
    players: 8,
    ponderous: { category: 'Person/Character', answer: 'Cleopatra', score: 8 },
    rounds: [
      { roundNumber: 1, category: "Art for Art's Sake", score: 10 },
      { roundNumber: 2, category: 'Double Ups', score: 6, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.sportAndGames, score: 9, isJoker: false },
      { roundNumber: 4, category: CATEGORY_TYPE.yayOrNay, score: 9, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 10, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 9, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.tvAndCinema, score: 9, isJoker: true },
      { roundNumber: 8, category: 'All Creatures', score: 9, isJoker: true },
    ],
    list: {
      category: CATEGORY_TYPE.geography,
      question: 'Any 10 of the 11 countries that the Nile runss through',
      score: 9,
    },
    finalScore: 106,
    position: 2,
    notes: 'Lose tie break for first. Year Akl Uni Founded',
    answerSheetId: '',
  },
  {
    date: '15-Mar-2022',
    location: 'Citizen Park',
    players: 10,
    ponderous: { category: 'Person/Character', answer: 'Tom Brady', score: 10 },
    rounds: [
      { roundNumber: 1, category: 'Picture Books', score: 10 },
      { roundNumber: 2, category: CATEGORY_TYPE.scienceAndNature, score: 10, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.tvAndCinema, score: 6, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.oddOneOut, score: 9, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 7, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 7, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.geography, score: 9, isJoker: true },
      {
        roundNumber: 8,
        category: 'Bob & Carol & Ted & Alice',
        score: 8,
        isJoker: false,
      },
    ],
    list: {
      category: 'Literature',
      question: 'Any 10 of the 22 characters of William Shakespeare that has more than 600 lines',
      score: 8,
    },
    finalScore: 99,
    position: 1,
    notes: '2nd place 98',
    answerSheetId: '',
  },
  {
    date: '22-Mar-2022',
    location: 'Citizen Park',
    players: 8,
    ponderous: { category: 'Food', answer: 'Pancakes', score: 9 },
    rounds: [
      { roundNumber: 1, category: 'Movie Masquerade', score: 9 },
      { roundNumber: 2, category: 'A Question of Sport', score: 7, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.artAndLiterature, score: 8, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.fiftyFifty, score: 8, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 9, isJoker: false },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 8, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.geography, score: 7, isJoker: false },
      { roundNumber: 8, category: 'The Last Shall Be First', score: 10, isJoker: true },
    ],
    list: {
      category: 'General',
      question: 'Any 10 of the 13 Papal names used more thaan 6 times',
      score: 4,
    },
    finalScore: 97,
    position: 2,
    notes: '1st place 102. No Graig, Nick 3 Rounds',
    answerSheetId: '',
  },
  {
    date: '29-Mar-2022',
    location: 'Citizen Park',
    players: 10,
    ponderous: { category: 'Conflict', answer: 'Waterloo', score: 10 },
    rounds: [
      { roundNumber: 1, category: 'Periodic Table', score: 10 },
      { roundNumber: 2, category: 'Fashion', score: 7, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.geography, score: 10, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.believeItOrNot, score: 7, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.music, score: 8, isJoker: false },
      { roundNumber: 6, category: 'Colours', score: 9, isJoker: true },
      { roundNumber: 7, category: CATEGORY_TYPE.tvAndCinema, score: 9, isJoker: false },
      { roundNumber: 8, category: CATEGORY_TYPE.sportAndGames, score: 10, isJoker: false },
    ],
    list: {
      category: CATEGORY_TYPE.foodAndDrink,
      question: "Any 10 of the 23 Whittaker's Classic Blocks",
      score: 10,
    },
    finalScore: 109,
    position: 3,
    notes: '1st place tie. 3rd after tiebreak. Flintstones',
    answerSheetId: '',
  },
  {
    date: '5-Apr-2022',
    location: 'Citizen Park',
    players: 9,
    ponderous: { category: 'Country', answer: 'Myanmar', score: 9 },
    rounds: [
      { roundNumber: 1, category: "Don't forget the lyrics", score: 10 },
      { roundNumber: 2, category: CATEGORY_TYPE.oldStuff, score: 7, isJoker: false },
      { roundNumber: 3, category: CATEGORY_TYPE.foodAndDrink, score: 9, isJoker: true },
      { roundNumber: 4, category: CATEGORY_TYPE.howMany, score: 8, isJoker: false },

      { roundNumber: 5, category: CATEGORY_TYPE.tvAndCinema, score: 10, isJoker: true },
      { roundNumber: 6, category: CATEGORY_TYPE.potLuck, score: 8, isJoker: false },
      { roundNumber: 7, category: CATEGORY_TYPE.sportAndGames, score: 7, isJoker: false },
      { roundNumber: 8, category: 'Kiwiana', score: 9, isJoker: false },
    ],
    list: {
      category: CATEGORY_TYPE.geography,
      question: 'Any 10 of the 38 Countries in the OECD',
      score: 10,
    },
    finalScore: 106,
    position: 1,
    notes: '2nd place 100. No Richard :(',
    answerSheetId: '',
  },
];
