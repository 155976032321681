import { Container, Spinner } from "reactstrap";

export const Loading = () => {
  return (
    <Container className="vertical-container">
      <Spinner color="primary" />
      <Spinner color="secondary" />
      <Spinner color="success" />
      <Spinner color="danger" />
      <Spinner color="warning" />
      <Spinner color="info" />
      <Spinner color="light" />
      <Spinner color="dark" />
    </Container>
  );
};
