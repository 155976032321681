export enum CATEGORY_TYPE {
  artAndLiterature = 'Art & Literature',
  believeItOrNot = 'Believe it or Not',
  fiftyFifty = 'Fifty Fifty',
  foodAndDrink = 'Food and Drink',
  geography = 'Geography',
  howMany = 'How Many',
  music = 'Music',
  oddOneOut = 'Odd One Out',
  oldStuff = 'Old Stuff',
  potLuck = 'Pot Luck',
  scienceAndNature = 'Science and Nature',
  sportAndGames = 'Sport & Games',
  tvAndCinema = 'Tv & Cinema',
  yayOrNay = 'Yay or Nay',
  alphabetSoup = 'Alphabet Soup',
  tvThemes = 'Tv Themes',
  fourLetterAnswers = 'Four Letter Answers',
}
