import {
  About,
  Capitals,
  Countries,
  FlagQuiz,
  Flags,
  HowMany,
  Movies,
  Oscars,
  PopulationOrdering,
  Test,
  Trivia,
} from '@view';
import { Route } from './routes.types';

export const ROUTES: Route[] = [
  { path: '/about', component: About, title: 'About' },
  { path: '/capitals', component: Capitals, title: 'Capitals' },
  { path: '/countries', component: Countries, title: 'Countries' },
  { path: '/flags', component: Flags, title: 'Flags' },
  { path: '/flag-quiz', component: FlagQuiz, title: 'Flag Quiz' },
  { path: '/trivia', component: Trivia, title: 'Trivia' },
  { path: '/movies', component: Movies, title: 'Movies' },
  { path: '/oscars', component: Oscars, title: 'Oscars' },
  {
    path: '/population',
    component: PopulationOrdering,
    title: 'Population Ordering',
  },
  { path: '/how-many', component: HowMany, title: 'How Many' },
  { path: '/test', component: Test, title: 'Test' },
];

export * from './routes.types';
