// Countries come from https://restcountries.com/
// https://restcountries.com/v3/all?fields=name,capital,flags

import * as store from "store"; // https://github.com/marcuswestin/store.js for local storage
import shuffle from 'lodash/shuffle';
import { Country } from '@models';
import { LOCAL_STORAGE } from '@constants';
import { now } from "lodash";

class RestCountriesService {
  getCountriesSorted = async () => {
    const countries = await this.getAllCountriesWithCapitals();

    return countries.sort((a, b) => a.name.common > b.name.common ? 1 : -1);
  }

  getNumberOfRandomCountries = async (num: number): Promise<Country[]> => {
    const countries = await this.getAllCountriesWithCapitals();

    const randomCountries = shuffle(countries).slice(0, num);

    return randomCountries;
  };

  getCountriesRandomised = async (): Promise<Country[]> => {
    const countries = await this.getAllCountriesWithCapitals();

    return shuffle(countries);
  };

  getAllCountryNames = async (): Promise<string[]> => {
    const countries = await this.getAllCountriesWithCapitals();

    return countries.map(country => country.name.common).sort();
  }

  getAllCapitalsAsDropDownOptions = async (): Promise<{ value: string, label: string }[]> => {
    const countries = await this.getAllCountriesWithCapitals();

    const sortedCountries = countries.map(country => country.capital[0]).sort();

    return sortedCountries.map(country => ({ value: country, label: country }));
  }

  getAllCapitals = async (): Promise<string[]> => {
    const countries = await this.getAllCountriesWithCapitals();

    return countries.map(country => country.capital[0]).sort();
  }

  getAllCountriesWithCapitals = async (): Promise<Country[]> => {
    const countries = await this.getAllCountries();

    return countries.filter(country => country.capital.length > 0);
  }

  getAllCountries = async (): Promise<Country[]> => {
    const localStorageCountries = store.get(LOCAL_STORAGE.countries);
    let countries: Country[] = localStorageCountries?.data;

    if (!countries || now() > localStorageCountries?.expiry) {
      console.log('🍏');
      const countriesResponse = await fetch('https://restcountries.com/v3/all?fields=name,capital,flags,population');

      countries = await countriesResponse.json();

      const oneDay = 86400000;

      const storage = { data: countries, expiry: now() + oneDay }

      store.set(LOCAL_STORAGE.countries, storage);
    }

    return countries;
  }
}

export const restCountriesService = new RestCountriesService();
