import { useState, useEffect } from "react";
import Select from 'react-select';
import { highScoreService, restCountriesService } from "@services";
import { Col, Container, Row, Button } from "reactstrap";
import { AnswerType, Country, DropDownModelOption } from "@models";
import { ANSWER_TYPE, LOCAL_STORAGE } from "@constants";
import "./capitals.page.css";

// @TODO:
// Can't click next without guessing?
// if dropdown is closed, and enter is pressed, should submit
// if streak is broken, get new set of countries? but only if score was more than 0
// replace existing flag page with new data set
// replace existing flag quiz with new data set
// can use hooks to make a generic quiz component?
// create link to wikipedia or google to look up the country?
// flag box should be the same size always, fit flag inside the box so the screen is always the same
// dropdown should go up to allow for keyboard? check how it currently works on mobile. may already do this
// fit buttons and dropdown to a max size - what should this be
// xtra small looks weird lol
// skeleton for when flag is loading? or fixed height?
// Copy the way I did the flag quiz to keep it a fixed height!

// From Toni
// On click of drop down.  Flag should animate shrink to make room especially on mobile
// 
// Buttons should be bigger or taller
// Make the buttons fun and satisfying to click. Animate?
// Remove the correct btn. Just keep next
// More space between buttons
// Dropdown options should go up not down. On mobile only 


export const Capitals = () => {
  // data
  const [countries, setCountries] = useState<Country[]>();
  const [capitals, setCapitals] = useState<DropDownModelOption[]>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [numCountries, setNumCountries] = useState(0);
  // user
  const [userChoice, setUserChoice] = useState<string | undefined>("");
  // game state
  const [answerState, setAnswerState] = useState<AnswerType>(ANSWER_TYPE.unanswered);
  // high score
  const [currentScore, setCurrentScore] = useState(0);
  const [highScore, setHighScore] = useState(0);

  useEffect(() => {
    const fetchAllCountriesAndCaptitals = async () => {
      const allCountries = await restCountriesService.getCountriesRandomised();
      setCountries(allCountries);
      setNumCountries(allCountries.length);

      const allCapitals = await restCountriesService.getAllCapitalsAsDropDownOptions();
      setCapitals(allCapitals);
    };

    fetchAllCountriesAndCaptitals();
    setHighScore(highScoreService.getHighScore(LOCAL_STORAGE.capitalCitiesHighScore));
  }, []);

  const handleGuessClick = () => {
    if (countries) {
      if (userChoice === countries[currentIndex].capital[0]) {
        setAnswerState(ANSWER_TYPE.correct);
        highScoreService.updateHighScoreIfRequired(LOCAL_STORAGE.capitalCitiesHighScore, currentScore + 1);
        if (currentScore + 1 > highScore) {
          setHighScore(currentScore + 1);
        }
        setCurrentScore(currentScore + 1)
      } else {
        setAnswerState(ANSWER_TYPE.incorrect);
        setCurrentScore(0);
      }
    }
  }

  const handleNextClick = () => {
    if (currentIndex < numCountries - 1) {
      setCurrentIndex(currentIndex + 1);
      setAnswerState(ANSWER_TYPE.unanswered);
    }
  }

  const handleRestartClick = () => {
    if (currentIndex === numCountries - 1) {
      window.location.reload();
    }
  }

  if (!countries) {
    return <div>Loading...</div>
  }

  return (
    <Container className="mx-auto mt-4 text-center center" fluid="md">
      <h1>What's my Capital?</h1>
      <Row className="text-center justify-content-md-center" key={countries[currentIndex].name.common}>
        <Col>
          <article>
            <h3>{countries[currentIndex].name.common}</h3>
            <div className="image-wrapper">
              <img src={countries[currentIndex].flags[1]} alt={`${countries[currentIndex].name.common} flag`} />
            </div>
            <p className="mt-2"><b>Population:</b> {countries[currentIndex].population.toLocaleString()}</p>
          </article>
        </Col>
      </Row>
      {answerState === ANSWER_TYPE.unanswered &&
        <Row className="justify-content-md-center">
          <Col>
            <Select className="text-left" options={capitals} menuPlacement="top" onChange={choice => setUserChoice(choice?.value)} isDisabled={answerState !== ANSWER_TYPE.unanswered} />
          </Col>
        </Row>
      }
      {answerState !== ANSWER_TYPE.unanswered &&
        <Row className="justify-content-md-center">
          <Col>
            <p><b>You chose: </b>{userChoice}</p>
            <p><b>Capital: </b>{countries[currentIndex].capital[0]}</p>
          </Col>
        </Row>
      }
      <Row className="mt-3 justify-content-md-center">
        <Col>
          <Button block={true} color="success" onClick={handleGuessClick} disabled={!userChoice || answerState !== ANSWER_TYPE.unanswered}>
            {answerState === ANSWER_TYPE.correct ? "🎉 Correct! 🎉" : answerState === ANSWER_TYPE.incorrect ? '❌ Wrong ❌' : 'Guess'}
          </Button>
        </Col>
      </Row>
      <Row className="mt-1 justify-content-md-center">
        <Col>
          {currentIndex < numCountries - 1 && <Button block={true} color="secondary" onClick={handleNextClick}>
            Next
          </Button>}
          {currentIndex === numCountries - 1 && <Button block={true} color="secondary" onClick={handleRestartClick}>Restart</Button>}
        </Col>
      </Row>
      <Row className="mt-3 justify-content-md-center">
        <Col>
          <p><b>Current score: </b>{currentScore?.toString() || '0'}</p>
          <p><b>High score: </b>{highScore?.toString() || '0'}</p>
        </Col>
      </Row>
    </Container>
  );
};
