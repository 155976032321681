import { SimpleQuestion } from '@models';
import { GoogleSpreadsheet } from 'google-spreadsheet';

class SpreadsheetService {
    getSpreadsheet = async () => {
        try {
            const doc = new GoogleSpreadsheet(process.env.REACT_APP_GOOGLE_SHEET_ID);

            await doc.useServiceAccountAuth({
                client_email: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL as string,
                private_key: process.env.REACT_APP_GOOGLE_PRIVATE_KEY as string,
            });
            await doc.loadInfo();

            return doc;
        } catch (error) {
            console.log(error);
        }
    };

    getSimpleQuestionsFromSpreasheet = async (): Promise<SimpleQuestion[]> => {
        const doc = await this.getSpreadsheet();

        if (!doc) { 
            return [] 
        };

        // @TODO: constantize the index
        const questionsSheet = doc.sheetsByIndex[10];
        const rawQuestions = await questionsSheet.getRows();

        const questions: SimpleQuestion[] = rawQuestions.map((rawQuestion) => {
            return { question: rawQuestion.question, answer: rawQuestion.answer }
        });

        return questions;
    }
}

export const spreadsheetService = new SpreadsheetService();
