export const countryNames = [
  { name: 'Afghanistan', aliases: [] },
  { name: 'Albania', aliases: [] },
  { name: 'Algeria', aliases: [] },
  { name: 'Andorra', aliases: [] },
  { name: 'Angola', aliases: [] },
  { name: 'Antigua and Barbuda', aliases: [] },
  { name: 'Argentina', aliases: [] },
  { name: 'Armenia', aliases: [] },
  { name: 'Australia', aliases: [] },
  { name: 'Austria', aliases: [] },
  { name: 'Azerbaijan', aliases: [] },
  { name: 'Bahamas', aliases: [] },
  { name: 'Bahrain', aliases: [] },
  { name: 'Bangladesh', aliases: [] },
  { name: 'Barbados', aliases: [] },
  { name: 'Belarus', aliases: [] },
  { name: 'Belgium', aliases: [] },
  { name: 'Belize', aliases: [] },
  { name: 'Benin', aliases: [] },
  { name: 'Bhutan', aliases: [] },
  { name: 'Bolivia', aliases: [] },
  { name: 'Bosnia and Herzegovina', aliases: ['Bosnia'] },
  { name: 'Botswana', aliases: [] },
  { name: 'Brazil', aliases: [] },
  { name: 'Brunei', aliases: [] },
  { name: 'Bulgaria', aliases: [] },
  { name: 'Burkina Faso', aliases: [] },
  { name: 'Burundi', aliases: [] },
  { name: 'Cabo Verde', aliases: ['Cape Verde'] },
  { name: 'Cambodia', aliases: [] },
  { name: 'Cameroon', aliases: [] },
  { name: 'Canada', aliases: [] },
  { name: 'Central African Republic', aliases: ['CAR'] },
  { name: 'Chad', aliases: [] },
  { name: 'Chile', aliases: [] },
  { name: 'China', aliases: [] },
  { name: 'Colombia', aliases: [] },
  { name: 'Comoros', aliases: [] },
  {
    name: 'Democratic Republic of the Congo',
    aliases: ['DR Congo', 'Congo-Kinshasa'],
  },
  { name: 'Republic of the Congo', aliases: ['Congo-Brazzaville'] },
  { name: 'Costa Rica', aliases: [] },
  { name: 'Croatia', aliases: [] },
  { name: 'Cuba', aliases: [] },
  { name: 'Cyprus', aliases: [] },
  { name: 'Czechia', aliases: ['Czech Republic'] },
  { name: 'Denmark', aliases: [] },
  { name: 'Djibouti', aliases: [] },
  { name: 'Dominica', aliases: [] },
  { name: 'Dominican Republic', aliases: [] },
  { name: 'Ecuador', aliases: [] },
  { name: 'Egypt', aliases: [] },
  { name: 'El Salvador', aliases: [] },
  { name: 'Equatorial Guinea', aliases: [] },
  { name: 'Eritrea', aliases: [] },
  { name: 'Estonia', aliases: [] },
  { name: 'Eswatini', aliases: ['Swaziland'] },
  { name: 'Ethiopia', aliases: [] },
  { name: 'Fiji', aliases: [] },
  { name: 'Finland', aliases: [] },
  { name: 'France', aliases: [] },
  { name: 'Gabon', aliases: [] },
  { name: 'Gambia', aliases: ['The Gambia'] },
  { name: 'Georgia', aliases: [] },
  { name: 'Germany', aliases: [] },
  { name: 'Ghana', aliases: [] },
  { name: 'Greece', aliases: [] },
  { name: 'Grenada', aliases: [] },
  { name: 'Guatemala', aliases: [] },
  { name: 'Guinea', aliases: [] },
  { name: 'Guinea-Bissau', aliases: [] },
  { name: 'Guyana', aliases: [] },
  { name: 'Haiti', aliases: [] },
  { name: 'Honduras', aliases: [] },
  { name: 'Hungary', aliases: [] },
  { name: 'Iceland', aliases: [] },
  { name: 'India', aliases: [] },
  { name: 'Indonesia', aliases: [] },
  { name: 'Iran', aliases: ['Islamic Republic of Iran'] },
  { name: 'Iraq', aliases: [] },
  { name: 'Ireland', aliases: [] },
  { name: 'Israel', aliases: [] },
  { name: 'Italy', aliases: [] },
  { name: 'Jamaica', aliases: [] },
  { name: 'Japan', aliases: [] },
  { name: 'Jordan', aliases: [] },
  { name: 'Kazakhstan', aliases: [] },
  { name: 'Kenya', aliases: [] },
  { name: 'Kiribati', aliases: [] },
  { name: 'Korea, North', aliases: ['North Korea'] },
  { name: 'Korea, South', aliases: ['South Korea'] },
  { name: 'Kosovo', aliases: [] },
  { name: 'Kuwait', aliases: [] },
  { name: 'Kyrgyzstan', aliases: [] },
  { name: 'Laos', aliases: ["Lao People's Democratic Republic"] },
  { name: 'Latvia', aliases: [] },
  { name: 'Lebanon', aliases: [] },
  { name: 'Lesotho', aliases: [] },
  { name: 'Liberia', aliases: [] },
  { name: 'Libya', aliases: [] },
  { name: 'Liechtenstein', aliases: [] },
  { name: 'Lithuania', aliases: [] },
  { name: 'Luxembourg', aliases: [] },
  { name: 'Madagascar', aliases: [] },
  { name: 'Malawi', aliases: [] },
  { name: 'Malaysia', aliases: [] },
  { name: 'Maldives', aliases: [] },
  { name: 'Mali', aliases: [] },
  { name: 'Malta', aliases: [] },
  { name: 'Marshall Islands', aliases: [] },
  { name: 'Mauritania', aliases: [] },
  { name: 'Mauritius', aliases: [] },
  { name: 'Mexico', aliases: [] },
  { name: 'Micronesia', aliases: ['Federated States of Micronesia'] },
  { name: 'Moldova', aliases: ['Republic of Moldova'] },
  { name: 'Monaco', aliases: [] },
  { name: 'Mongolia', aliases: [] },
  { name: 'Montenegro', aliases: [] },
  { name: 'Morocco', aliases: [] },
  { name: 'Mozambique', aliases: [] },
  { name: 'Myanmar', aliases: ['Burma'] },
  { name: 'Namibia', aliases: [] },
  { name: 'Nauru', aliases: [] },
  { name: 'Nepal', aliases: [] },
  { name: 'Netherlands', aliases: [] },
  { name: 'New Zealand', aliases: [] },
  { name: 'Nicaragua', aliases: [] },
  { name: 'Niger', aliases: [] },
  { name: 'Nigeria', aliases: [] },
  { name: 'North Macedonia', aliases: ['Macedonia'] },
  { name: 'Norway', aliases: [] },
  { name: 'Oman', aliases: [] },
  { name: 'Pakistan', aliases: [] },
  { name: 'Palau', aliases: [] },
  { name: 'Palestine', aliases: ['State of Palestine'] },
  { name: 'Panama', aliases: [] },
  { name: 'Papua New Guinea', aliases: [] },
  { name: 'Paraguay', aliases: [] },
  { name: 'Peru', aliases: [] },
  { name: 'Philippines', aliases: [] },
  { name: 'Poland', aliases: [] },
  { name: 'Portugal', aliases: [] },
  { name: 'Qatar', aliases: [] },
  { name: 'Romania', aliases: [] },
  { name: 'Russia', aliases: ['Russian Federation'] },
  { name: 'Rwanda', aliases: [] },
  { name: 'Saint Kitts and Nevis', aliases: [] },
  { name: 'Saint Lucia', aliases: [] },
  { name: 'Saint Vincent and the Grenadines', aliases: [] },
  { name: 'Samoa', aliases: [] },
  { name: 'San Marino', aliases: [] },
  { name: 'Sao Tome and Principe', aliases: [] },
  { name: 'Saudi Arabia', aliases: [] },
  { name: 'Senegal', aliases: [] },
  { name: 'Serbia', aliases: [] },
  { name: 'Seychelles', aliases: [] },
  { name: 'Sierra Leone', aliases: [] },
  { name: 'Singapore', aliases: [] },
  { name: 'Slovakia', aliases: [] },
  { name: 'Slovenia', aliases: [] },
  { name: 'Solomon Islands', aliases: [] },
  { name: 'Somalia', aliases: [] },
  { name: 'South Africa', aliases: [] },
  { name: 'South Sudan', aliases: [] },
  { name: 'Spain', aliases: [] },
  { name: 'Sri Lanka', aliases: [] },
  { name: 'Sudan', aliases: [] },
  { name: 'Suriname', aliases: [] },
  { name: 'Sweden', aliases: [] },
  { name: 'Switzerland', aliases: [] },
  { name: 'Syria', aliases: ['Syrian Arab Republic'] },
  { name: 'Taiwan', aliases: [] },
  { name: 'Tajikistan', aliases: [] },
  { name: 'Tanzania', aliases: [] },
  { name: 'Thailand', aliases: [] },
  { name: 'Timor-Leste', aliases: ['East Timor'] },
  { name: 'Togo', aliases: [] },
  { name: 'Tonga', aliases: [] },
  { name: 'Trinidad and Tobago', aliases: [] },
  { name: 'Tunisia', aliases: [] },
  { name: 'Turkey', aliases: ['Turkiye'] },
  { name: 'Turkmenistan', aliases: [] },
  { name: 'Tuvalu', aliases: [] },
  { name: 'Uganda', aliases: [] },
  { name: 'Ukraine', aliases: [] },
  { name: 'United Arab Emirates', aliases: ['UAE'] },
  {
    name: 'United Kingdom',
    aliases: ['UK', 'Britain', 'Great Britain', 'England'],
  },
  { name: 'United States', aliases: ['USA', 'US', 'America'] },
  { name: 'Uruguay', aliases: [] },
  { name: 'Uzbekistan', aliases: [] },
  { name: 'Vanuatu', aliases: [] },
  { name: 'Vatican City', aliases: ['Holy See'] },
  { name: 'Venezuela', aliases: [] },
  { name: 'Vietnam', aliases: ['Viet Nam'] },
  { name: 'Yemen', aliases: [] },
  { name: 'Zambia', aliases: [] },
  { name: 'Zimbabwe', aliases: [] },
];
