import { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { ANSWER_TYPE } from "@constants";
import { AnswerType, Flag } from "@models";
import { flagService, utilService } from "@services";
import { QuestionSection, GameOver } from "@view";
import "./flag-quiz.page.css";

export const FlagQuiz = () => {
  const numRounds = 10;
  const numOptions = 5;
  const [currentRound, setCurrentRound] = useState<number>(0);
  const [chunkedRounds, setChunkedRounds] =
    useState<Flag[][] | undefined>(undefined);
  const [answerResult, setAnswerResult] = useState<AnswerType>(
    ANSWER_TYPE.unanswered
  );
  const [flagOptions, setFlagOptions] = useState<Flag[]>([]);
  const [flagAnswer, setFlagAnswer] = useState<Flag | undefined>(undefined);
  const [chosenFlag, setChosenFlag] = useState<Flag | undefined>(undefined);
  const [questionAnswered, setQuestionAnswered] = useState<boolean>(false);
  const [score, setScore] = useState<number>(0);
  const [gameOver, setGameOver] = useState<boolean>(false);

  useEffect(() => {
    const rounds = flagService.getFlags({ numRounds, numOptions });

    setChunkedRounds(rounds);
  }, [gameOver, numRounds, numOptions]);

  useEffect(() => {
    // Set the current flag round based on the round number.
    if (chunkedRounds) {
      const randomNumber = utilService.getRandomNumber(numOptions);

      setFlagAnswer(chunkedRounds[currentRound][randomNumber]);
      setFlagOptions(chunkedRounds[currentRound]);
    }
  }, [chunkedRounds, currentRound, numRounds, numOptions]);

  const resetGame = () => {
    setScore(0);
    setChosenFlag(undefined);
    setQuestionAnswered(false);
    setAnswerResult(ANSWER_TYPE.unanswered);
    setCurrentRound(0);
    setGameOver(false);
  };

  const handleTryAgainClick = () => {
    resetGame();
  };

  const handleAnswerClick = (clickedFlag: Flag) => {
    if (!questionAnswered) {
      setChosenFlag(clickedFlag);
      setQuestionAnswered(true);
      if (clickedFlag.country === flagAnswer?.country) {
        setScore(score + 1);
        setAnswerResult(ANSWER_TYPE.correct);
      } else {
        setAnswerResult(ANSWER_TYPE.incorrect);
      }
    }
  };

  const handleNextQuestionClick = () => {
    setChosenFlag(undefined);
    setQuestionAnswered(false);
    setAnswerResult(ANSWER_TYPE.unanswered);
    if (currentRound < numRounds - 1) {
      setCurrentRound(currentRound + 1);
    } else {
      setGameOver(true);
    }
  };

  const renderQuiz = () => (
    <div className="full-page center">
      <h2>Choose the correct Country</h2>
      <h3>
        Question {currentRound + 1} of {numRounds}
      </h3>
      {flagAnswer && <QuestionSection flag={flagAnswer} />}
      <div className="quiz__buttons">
        {flagOptions.map((flag) => {
          const buttonVariant =
            flagAnswer?.country === flag.country
              ? "success"
              : chosenFlag?.country === flag.country
              ? "danger"
              : "secondary";

          return (
            <div key={flag.country}>
              <Button
                block
                className="mb-1"
                color={questionAnswered ? buttonVariant : "secondary"}
                onClick={() => handleAnswerClick(flag)}
                size="lg"
              >
                {flag.country}
              </Button>
            </div>
          );
        })}
      </div>
      <hr />
      {questionAnswered &&
        (answerResult === ANSWER_TYPE.correct ? (
          <p>Well done!</p>
        ) : (
          <p>Nope!</p>
        ))}
      {questionAnswered && (
        <Button color="primary" onClick={handleNextQuestionClick}>
          Next question
        </Button>
      )}
    </div>
  );

  return gameOver ? (
    <GameOver
      onTryAgain={handleTryAgainClick}
      score={score}
      numRounds={numRounds}
    />
  ) : (
    renderQuiz()
  );
};
