import { Table } from 'reactstrap';
import { MEDALS } from '@constants';
import { suqResults } from '@data';

export const CustomTable = () => (
  <Table responsive dark striped>
    <thead>
      <tr>
        <th>No.</th>
        <th>Date</th>
        <th>Location</th>
        <th>Players</th>
        <th>Final Score</th>
        <th>Pole Position</th>
      </tr>
    </thead>
    <tbody>
      {suqResults.map((result, index) => {
        const medal =
          result.position === 1
            ? MEDALS.gold
            : result.position === 2
            ? MEDALS.silver
            : result.position === 3
            ? MEDALS.bronze
            : '';
        return (
          <tr key={result.date}>
            <th scope="row">{index + 1}</th>
            <td>{result.date}</td>
            <td>{result.location}</td>
            <td>{result.players}</td>
            <td>{result.finalScore}</td>
            <td>{result.position <= 3 ? medal : result.position}</td>
          </tr>
        );
      })}
    </tbody>
  </Table>
);
