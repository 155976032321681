import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { dataService } from '@services';

export const CustomBarChart = () => {
  return (
    <ResponsiveContainer width={'100%'} height={250}>
      <BarChart
        width={1000}
        height={250}
        data={dataService.getCategoriesBarChartData()}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="median" fill="#8884d8" />
        <Bar dataKey="average" fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
  );
};
