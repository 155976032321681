import { CATEGORY_TYPE } from '@constants';
import { suqResults } from '@data';
import { utilService } from '@services';

type FinalScoreLineGraphDataPoint = {
  date: string;
  finalScore: number;
};

type PositionLineGraphDataPoint = {
  date: string;
  position: number;
};

type PolePositionsData = {
  fill: string;
  name: string;
  value: number;
};

class DataService {
  getPolePositions = (): PolePositionsData[] => {
    const totalGold = suqResults.filter(
      (result) => result.position === 1
    ).length;
    const totalSilver = suqResults.filter(
      (result) => result.position === 2
    ).length;
    const totalBronze = suqResults.filter(
      (result) => result.position === 3
    ).length;
    const totalNoPlacement = suqResults.filter(
      (result) => result.position > 3
    ).length;

    return [
      {
        fill: '#FFD700',
        name: 'Gold',
        value: totalGold,
      },
      {
        fill: '#aaa9ad',
        name: 'Silver',
        value: totalSilver,
      },
      {
        fill: '#CD7F32',
        name: 'Bronze',
        value: totalBronze,
      },
      {
        fill: '#000000',
        name: 'No Medal',
        value: totalNoPlacement,
      },
    ];
  };

  getPlayerCountData = (): PolePositionsData[] => {
    const six = suqResults.filter((result) => result.players === 6).length;
    const seven = suqResults.filter((result) => result.players === 7).length;
    const eight = suqResults.filter((result) => result.players === 8).length;
    const nine = suqResults.filter((result) => result.players === 9).length;
    const ten = suqResults.filter((result) => result.players === 10).length;
    const eleven = suqResults.filter((result) => result.players === 11).length;

    return [
      {
        fill: 'blue',
        name: 'Six',
        value: six,
      },
      {
        fill: 'red',
        name: 'Seven',
        value: seven,
      },
      {
        fill: 'green',
        name: 'Eight',
        value: eight,
      },
      {
        fill: 'purple',
        name: 'Nine',
        value: nine,
      },
      {
        fill: 'orange',
        name: 'Ten',
        value: ten,
      },
      {
        fill: 'black',
        name: 'Eleven',
        value: eleven,
      },
    ];
  };

  getFinalScoreLineChartData = (): FinalScoreLineGraphDataPoint[] => {
    return suqResults.map(({ date, finalScore }) => {
      return { date, finalScore };
    });
  };

  getPositionLineChartData = (): PositionLineGraphDataPoint[] => {
    return suqResults.map(({ date, position }) => {
      return { date, position };
    });
  };

  getAvgAndMedianForCategory = (
    category: CATEGORY_TYPE
  ): { categoryAvg: number; categoryMedian: number } => {
    const allCategoryRounds = suqResults.flatMap((result) => {
      return result.rounds.filter((round) => round.category === category);
    });

    const categoryScores = allCategoryRounds.map((round) => round.score);
    const categorySum = categoryScores.reduce(
      (accumulator, score) => accumulator + score
    );
    const categoryAvg = +(categorySum / allCategoryRounds.length).toFixed(2);
    const categoryMedian = utilService.getMedian(categoryScores);

    return { categoryAvg, categoryMedian };
  };

  getCategoriesBarChartData = () => {
    const { categoryAvg: tvAndCinemaAvg, categoryMedian: tvAndCinemaMedian } =
      this.getAvgAndMedianForCategory(CATEGORY_TYPE.tvAndCinema);

    const {
      categoryAvg: sportAndGamesAvg,
      categoryMedian: sportAndGamesMedian,
    } = this.getAvgAndMedianForCategory(CATEGORY_TYPE.sportAndGames);

    const { categoryAvg: potLuckAvg, categoryMedian: potLuckMedian } =
      this.getAvgAndMedianForCategory(CATEGORY_TYPE.potLuck);

    const {
      categoryAvg: artAndLiteratureAvg,
      categoryMedian: artAndLiteratureMedian,
    } = this.getAvgAndMedianForCategory(CATEGORY_TYPE.artAndLiterature);

    const { categoryAvg: geographyAvg, categoryMedian: geographyMedian } =
      this.getAvgAndMedianForCategory(CATEGORY_TYPE.geography);

    const { categoryAvg: foodAndDrinkAvg, categoryMedian: foodAndDrinkMedian } =
      this.getAvgAndMedianForCategory(CATEGORY_TYPE.foodAndDrink);

    const { categoryAvg: musicAvg, categoryMedian: musicMedian } =
      this.getAvgAndMedianForCategory(CATEGORY_TYPE.music);

    const data = [
      {
        name: CATEGORY_TYPE.tvAndCinema,
        average: tvAndCinemaAvg,
        median: tvAndCinemaMedian,
      },
      {
        name: CATEGORY_TYPE.sportAndGames,
        average: sportAndGamesAvg,
        median: sportAndGamesMedian,
      },
      {
        name: CATEGORY_TYPE.potLuck,
        average: potLuckAvg,
        median: potLuckMedian,
      },
      {
        name: CATEGORY_TYPE.artAndLiterature,
        average: artAndLiteratureAvg,
        median: artAndLiteratureMedian,
      },
      {
        name: CATEGORY_TYPE.geography,
        average: geographyAvg,
        median: geographyMedian,
      },
      {
        name: CATEGORY_TYPE.foodAndDrink,
        average: foodAndDrinkAvg,
        median: foodAndDrinkMedian,
      },
      {
        name: CATEGORY_TYPE.music,
        average: musicAvg,
        median: musicMedian,
      },
    ];

    return data.sort((a, b) => b.average - a.average);
  };
}

export const dataService = new DataService();
