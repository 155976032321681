import { Resource } from "@models";

export const resources: Resource[] = [
  {
    title: "Trivia Trainer",
    url: "https://triviatrainer.netlify.app/",
    category: "Interactive Quiz",
    description:
      "1 question at a time. Background gradient is really nice. Done with :before: and :after: elements",
    meta: "Opentdb",
    src: "",
  },
  {
    title: "Quiz",
    url: "https://wekiban.github.io/Quiz-App/#",
    category: "Interactive Quiz",
    description: "Sound. Has a simple question answer look.",
    meta: "Opentdb",
    src: "https://github.com/WeKiBan/Quiz-App",
  },
  {
    title: "The Quiz Channel",
    url: "https://www.youtube.com/c/TheQuizChannel",
    category: "Youtube",
    description: "Youtube quizzes. Lots of music related.",
    meta: "",
    src: "",
  },
  {
    title: "Funny Fri Quiz",
    url: "https://www.youtube.com/c/FunnyFriQuiz",
    category: "Youtube",
    description: "Youtube quizzes. Some quite easy.",
    meta: "",
    src: "",
  },
  {
    title: "Phobia Wiki",
    url: "https://phobia.wikia.org/wiki/Phobia_wiki",
    category: "Resource",
    description: "Phobia wiki - good to help learn about phobias",
    meta: "",
    src: "",
  },
  {
    title: "Quiz Me",
    url: "https://www.youtube.com/c/QuizMeOwl",
    category: "Youtube",
    description: "Youtube quizzes. Good general knowledge",
    meta: "",
    src: "",
  },
  {
    title: "Challenge the brain",
    url: "https://www.challengethebrain.com/",
    category: "Questions",
    description:
      "Multiple lists of questions. May be good to use as a free text resource.",
    meta: "",
    src: "",
  },
  {
    title: "Sporcle",
    url: "https://www.sporcle.com/",
    category: "Interactive Quiz",
    description:
      "The best quiz site hands down. Saves your progress. Achievements.",
    meta: "",
    src: "",
  },
  {
    title: "Bang Jan",
    url: "https://www.youtube.com/channel/UCHktqC9OZrwCf3vT4yuk72g",
    category: "Youtube",
    description: "Mastermind episodes",
    meta: "",
    src: "",
  },
];
