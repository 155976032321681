import classNames from "classnames";
import { Flag } from "@models";
import "./question-section.component.scss";

export const QuestionSection = ({
  className,
  flag,
  onClick,
}: {
  className?: string;
  flag: Flag;
  onClick?: () => void;
}) => {
  const classes = classNames(className, "question__panel");

  const { imageUrl, country } = flag;

  return (
    <div className={classes} onClick={onClick}>
      <img
        className="question__content"
        src={imageUrl}
        alt={`${country}-flag`}
      />
    </div>
  );
};
