import { Button } from "reactstrap";

export const GameOver = ({
  score,
  numRounds,
  onTryAgain,
}: {
  score: number;
  numRounds: number;
  onTryAgain: () => void;
}) => {
  return (
    <div className="center mt-5">
      <h1>Game over man, Game over.</h1>
      <h3>
        You got {score} out of {numRounds}.
      </h3>
      <Button onClick={onTryAgain}>Try again</Button>
    </div>
  );
};
