import shuffle from 'lodash/shuffle';
import { countries } from '@data';
import { OldCountry } from '@models';

class CountryService {
  getRandomCountries = (numOptions: number): OldCountry[] => {
    const randomCountries = shuffle(countries).slice(0, numOptions);

    return randomCountries;
  };
}

export const countryService = new CountryService();
