import { Switch, Route, useLocation } from 'react-router-dom';
import { ROUTES } from '@routes';
import {
  Home,
  Header,
  Suq,
} from '@view';
import './App.scss';
import { useEffect, useState } from 'react';


export const App = () => {
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState(location.pathname);

  useEffect(() => {
    setCurrentRoute(location.pathname);
  },[location])

  const routesWithoutHeader = ['/capitals'];

  return (
    <>
      {routesWithoutHeader.includes(currentRoute) || <Header />}
      <Switch>
        {ROUTES.map(({ component, path }) => (
          <Route component={component} key={path} path={path} />
        ))}
        <Route path="/suq">
          <Suq />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </>
  )
}
