import { Country } from "@models";
import {restCountriesService} from '@services'
import { FlagCard } from "@view";
import { useEffect, useState } from "react";

export const Flags = () => {
  const [countries, setCountries] = useState<Country[]>([]);

  useEffect(() => {
    const fetchCountries = async () => {
      const allCountries = await restCountriesService.getCountriesSorted();
      setCountries(allCountries);
    };

    fetchCountries();
  }, []);

  if (!countries) {
    return <div>Loading</div>;
  }
  return (
    <div className="cards">
      {countries.map((country) => (
        <FlagCard flag={{imageUrl: country.flags[1], country: country.name.common}} key={country.name.common} />
      ))}
    </div>
  );
};
