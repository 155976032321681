// data sourced from https://worldpopulationreview.com/countries, up to date as of 12/9/2022

import { OldCountry } from '@models';

export const countries: OldCountry[] = [
  {
    rank: 1,
    name: 'China',
    pop2022: '1425887.3370',
    pop2021: '1425893.4650',
    growthRate: '1.0000',
    area: 9706961,
    density: '146.8933',
  },
  {
    rank: 2,
    name: 'India',
    pop2022: '1417173.1730',
    pop2021: '1407563.8420',
    growthRate: '1.0068',
    area: 3287590,
    density: '431.0675',
  },
  {
    rank: 3,
    name: 'United States',
    pop2022: '338289.8570',
    pop2021: '336997.6240',
    growthRate: '1.0038',
    area: 9372610,
    density: '36.0935',
  },
  {
    rank: 4,
    name: 'Indonesia',
    pop2022: '275501.3390',
    pop2021: '273753.1910',
    growthRate: '1.0064',
    area: 1904569,
    density: '144.6529',
  },
  {
    rank: 5,
    name: 'Pakistan',
    pop2022: '235824.8620',
    pop2021: '231402.1170',
    growthRate: '1.0191',
    area: 881912,
    density: '267.4018',
  },
  {
    rank: 6,
    name: 'Nigeria',
    pop2022: '218541.2120',
    pop2021: '213401.3230',
    growthRate: '1.0241',
    area: 923768,
    density: '236.5759',
  },
  {
    rank: 7,
    name: 'Brazil',
    pop2022: '215313.4980',
    pop2021: '214326.2230',
    growthRate: '1.0046',
    area: 8515767,
    density: '25.2841',
  },
  {
    rank: 8,
    name: 'Bangladesh',
    pop2022: '171186.3720',
    pop2021: '169356.2510',
    growthRate: '1.0108',
    area: 147570,
    density: '1160.0350',
  },
  {
    rank: 9,
    name: 'Russia',
    pop2022: '144713.3140',
    pop2021: '145102.7550',
    growthRate: '0.9973',
    area: 17098242,
    density: '8.4636',
  },
  {
    rank: 10,
    name: 'Mexico',
    pop2022: '127504.1250',
    pop2021: '126705.1380',
    growthRate: '1.0063',
    area: 1964375,
    density: '64.9082',
  },
  {
    rank: 11,
    name: 'Japan',
    pop2022: '123951.6920',
    pop2021: '124612.5300',
    growthRate: '0.9947',
    area: 377930,
    density: '327.9753',
  },
  {
    rank: 12,
    name: 'Ethiopia',
    pop2022: '123379.9240',
    pop2021: '120283.0260',
    growthRate: '1.0257',
    area: 1104300,
    density: '111.7268',
  },
  {
    rank: 13,
    name: 'Philippines',
    pop2022: '115559.0090',
    pop2021: '113880.3280',
    growthRate: '1.0147',
    area: 342353,
    density: '337.5434',
  },
  {
    rank: 14,
    name: 'Egypt',
    pop2022: '110990.1030',
    pop2021: '109262.1780',
    growthRate: '1.0158',
    area: 1002450,
    density: '110.7188',
  },
  {
    rank: 15,
    name: 'DR Congo',
    pop2022: '99010.2120',
    pop2021: '95894.1180',
    growthRate: '1.0325',
    area: 2344858,
    density: '42.2244',
  },
  {
    rank: 16,
    name: 'Vietnam',
    pop2022: '98186.8560',
    pop2021: '97468.0290',
    growthRate: '1.0074',
    area: 331212,
    density: '296.4472',
  },
  {
    rank: 17,
    name: 'Iran',
    pop2022: '88550.5700',
    pop2021: '87923.4320',
    growthRate: '1.0071',
    area: 1648195,
    density: '53.7258',
  },
  {
    rank: 18,
    name: 'Turkey',
    pop2022: '85341.2410',
    pop2021: '84775.4040',
    growthRate: '1.0067',
    area: 783562,
    density: '108.9145',
  },
  {
    rank: 19,
    name: 'Germany',
    pop2022: '83369.8430',
    pop2021: '83408.5540',
    growthRate: '0.9995',
    area: 357114,
    density: '233.4544',
  },
  {
    rank: 20,
    name: 'Thailand',
    pop2022: '71697.0300',
    pop2021: '71601.1030',
    growthRate: '1.0013',
    area: 513120,
    density: '139.7276',
  },
  {
    rank: 21,
    name: 'United Kingdom',
    pop2022: '67508.9360',
    pop2021: '67281.0390',
    growthRate: '1.0034',
    area: 242900,
    density: '277.9289',
  },
  {
    rank: 22,
    name: 'Tanzania',
    pop2022: '65497.7480',
    pop2021: '63588.3340',
    growthRate: '1.0300',
    area: 945087,
    density: '69.3034',
  },
  {
    rank: 23,
    name: 'France',
    pop2022: '64626.6280',
    pop2021: '64531.4440',
    growthRate: '1.0015',
    area: 551695,
    density: '117.1419',
  },
  {
    rank: 24,
    name: 'South Africa',
    pop2022: '59893.8850',
    pop2021: '59392.2550',
    growthRate: '1.0084',
    area: 1221037,
    density: '49.0517',
  },
  {
    rank: 25,
    name: 'Italy',
    pop2022: '59037.4740',
    pop2021: '59240.3290',
    growthRate: '0.9966',
    area: 301336,
    density: '195.9191',
  },
  {
    rank: 26,
    name: 'England',
    pop2022: '57367.8110',
    growthRate: '1.0072',
    area: 130279,
    density: '440.3458',
  },
  {
    rank: 27,
    name: 'Myanmar',
    pop2022: '54179.3060',
    pop2021: '53798.0840',
    growthRate: '1.0071',
    area: 676578,
    density: '80.0784',
  },
  {
    rank: 28,
    name: 'Kenya',
    pop2022: '54027.4870',
    pop2021: '53005.6140',
    growthRate: '1.0193',
    area: 580367,
    density: '93.0919',
  },
  {
    rank: 29,
    name: 'Colombia',
    pop2022: '51874.0240',
    pop2021: '51516.5620',
    growthRate: '1.0069',
    area: 1141748,
    density: '45.4339',
  },
  {
    rank: 30,
    name: 'South Korea',
    pop2022: '51815.8100',
    pop2021: '51830.1390',
    growthRate: '0.9997',
    area: 100210,
    density: '517.0722',
  },
  {
    rank: 31,
    name: 'Spain',
    pop2022: '47558.6300',
    pop2021: '47486.9350',
    growthRate: '1.0015',
    area: 505992,
    density: '93.9909',
  },
  {
    rank: 32,
    name: 'Uganda',
    pop2022: '47249.5850',
    pop2021: '45853.7780',
    growthRate: '1.0304',
    area: 241550,
    density: '195.6100',
  },
  {
    rank: 33,
    name: 'Sudan',
    pop2022: '46874.2040',
    pop2021: '45657.2020',
    growthRate: '1.0267',
    area: 1886068,
    density: '24.8529',
  },
  {
    rank: 34,
    name: 'Argentina',
    pop2022: '45510.3180',
    pop2021: '45276.7800',
    growthRate: '1.0052',
    area: 2780400,
    density: '16.3683',
  },
  {
    rank: 35,
    name: 'Algeria',
    pop2022: '44903.2250',
    pop2021: '44177.9690',
    growthRate: '1.0164',
    area: 2381741,
    density: '18.8531',
  },
  {
    rank: 36,
    name: 'Iraq',
    pop2022: '44496.1220',
    pop2021: '43533.5920',
    growthRate: '1.0221',
    area: 438317,
    density: '101.5158',
  },
  {
    rank: 37,
    name: 'Afghanistan',
    pop2022: '41128.7710',
    pop2021: '40099.4620',
    growthRate: '1.0257',
    area: 652230,
    density: '63.0587',
  },
  {
    rank: 38,
    name: 'Poland',
    pop2022: '39857.1450',
    pop2021: '38307.7260',
    growthRate: '1.0404',
    area: 312679,
    density: '127.4698',
  },
  {
    rank: 39,
    name: 'Ukraine',
    pop2022: '39701.7390',
    pop2021: '43531.4220',
    growthRate: '0.9120',
    area: 603500,
    density: '65.7858',
  },
  {
    rank: 40,
    name: 'Canada',
    pop2022: '38454.3270',
    pop2021: '38155.0120',
    growthRate: '1.0078',
    area: 9984670,
    density: '3.8513',
  },
  {
    rank: 41,
    name: 'Morocco',
    pop2022: '37457.9710',
    pop2021: '37076.5840',
    growthRate: '1.0103',
    area: 446550,
    density: '83.8830',
  },
  {
    rank: 42,
    name: 'Saudi Arabia',
    pop2022: '36408.8200',
    pop2021: '35950.3960',
    growthRate: '1.0128',
    area: 2149690,
    density: '16.9368',
  },
  {
    rank: 43,
    name: 'Angola',
    pop2022: '35588.9870',
    pop2021: '34503.7740',
    growthRate: '1.0315',
    area: 1246700,
    density: '28.5466',
  },
  {
    rank: 44,
    name: 'Uzbekistan',
    pop2022: '34627.6520',
    pop2021: '34081.4490',
    growthRate: '1.0160',
    area: 447400,
    density: '77.3975',
  },
  {
    rank: 45,
    name: 'Peru',
    pop2022: '34049.5880',
    pop2021: '33715.4710',
    growthRate: '1.0099',
    area: 1285216,
    density: '26.4933',
  },
  {
    rank: 46,
    name: 'Malaysia',
    pop2022: '33938.2210',
    pop2021: '33573.8740',
    growthRate: '1.0109',
    area: 330803,
    density: '102.5934',
  },
  {
    rank: 47,
    name: 'Yemen',
    pop2022: '33696.6140',
    pop2021: '32981.6410',
    growthRate: '1.0217',
    area: 527968,
    density: '63.8232',
  },
  {
    rank: 48,
    name: 'Ghana',
    pop2022: '33475.8700',
    pop2021: '32833.0310',
    growthRate: '1.0196',
    area: 238533,
    density: '140.3406',
  },
  {
    rank: 49,
    name: 'Mozambique',
    pop2022: '32969.5180',
    pop2021: '32077.0720',
    growthRate: '1.0278',
    area: 801590,
    density: '41.1302',
  },
  {
    rank: 50,
    name: 'Nepal',
    pop2022: '30547.5800',
    pop2021: '30034.9890',
    growthRate: '1.0171',
    area: 147181,
    density: '207.5511',
  },
  {
    rank: 51,
    name: 'Madagascar',
    pop2022: '29611.7140',
    pop2021: '28915.6530',
    growthRate: '1.0241',
    area: 587041,
    density: '50.4423',
  },
  {
    rank: 52,
    name: 'Venezuela',
    pop2022: '28301.6960',
    pop2021: '28199.8670',
    growthRate: '1.0036',
    area: 916445,
    density: '30.8820',
  },
  {
    rank: 53,
    name: 'Ivory Coast',
    pop2022: '28160.5420',
    pop2021: '27478.2490',
    growthRate: '1.0248',
    area: 322463,
    density: '87.3295',
  },
  {
    rank: 54,
    name: 'Cameroon',
    pop2022: '27914.5360',
    pop2021: '27198.6280',
    growthRate: '1.0263',
    area: 475442,
    density: '58.7128',
  },
  {
    rank: 55,
    name: 'Niger',
    pop2022: '26207.9770',
    pop2021: '25252.7220',
    growthRate: '1.0378',
    area: 1267000,
    density: '20.6851',
  },
  {
    rank: 56,
    name: 'Australia',
    pop2022: '26177.4130',
    pop2021: '25921.0890',
    growthRate: '1.0099',
    area: 7692024,
    density: '3.4032',
  },
  {
    rank: 57,
    name: 'North Korea',
    pop2022: '26069.4160',
    pop2021: '25971.9090',
    growthRate: '1.0038',
    area: 120538,
    density: '216.2755',
  },
  {
    rank: 58,
    name: 'Taiwan',
    pop2022: '23893.3940',
    pop2021: '23859.9120',
    growthRate: '1.0014',
    area: 36193,
    density: '660.1662',
  },
  {
    rank: 59,
    name: 'Burkina Faso',
    pop2022: '22673.7620',
    pop2021: '22100.6830',
    growthRate: '1.0259',
    area: 272967,
    density: '83.0641',
  },
  {
    rank: 60,
    name: 'Mali',
    pop2022: '22593.5900',
    pop2021: '21904.9830',
    growthRate: '1.0314',
    area: 1240192,
    density: '18.2178',
  },
  {
    rank: 61,
    name: 'Syria',
    pop2022: '22125.2490',
    pop2021: '21324.3670',
    growthRate: '1.0376',
    area: 185180,
    density: '119.4797',
  },
  {
    rank: 62,
    name: 'Sri Lanka',
    pop2022: '21832.1430',
    pop2021: '21773.4410',
    growthRate: '1.0027',
    area: 65610,
    density: '332.7563',
  },
  {
    rank: 63,
    name: 'Malawi',
    pop2022: '20405.3170',
    pop2021: '19889.7420',
    growthRate: '1.0259',
    area: 118484,
    density: '172.2200',
  },
  {
    rank: 64,
    name: 'Zambia',
    pop2022: '20017.6750',
    pop2021: '19473.1250',
    growthRate: '1.0280',
    area: 752612,
    density: '26.5976',
  },
  {
    rank: 65,
    name: 'Romania',
    pop2022: '19659.2670',
    pop2021: '19328.5600',
    growthRate: '1.0171',
    area: 238391,
    density: '82.4665',
  },
  {
    rank: 66,
    name: 'Chile',
    pop2022: '19603.7330',
    pop2021: '19493.1840',
    growthRate: '1.0057',
    area: 756102,
    density: '25.9274',
  },
  {
    rank: 67,
    name: 'Kazakhstan',
    pop2022: '19397.9980',
    pop2021: '19196.4650',
    growthRate: '1.0105',
    area: 2724900,
    density: '7.1188',
  },
  {
    rank: 68,
    name: 'Ecuador',
    pop2022: '18001',
    pop2021: '17797.7370',
    growthRate: '1.0114',
    area: 276841,
    density: '65.0229',
  },
  {
    rank: 69,
    name: 'Guatemala',
    pop2022: '17843.9080',
    pop2021: '17608.4830',
    growthRate: '1.0134',
    area: 108889,
    density: '163.8725',
  },
  {
    rank: 70,
    name: 'Chad',
    pop2022: '17723.3150',
    pop2021: '17179.7400',
    growthRate: '1.0316',
    area: 1284000,
    density: '13.8032',
  },
  {
    rank: 71,
    name: 'Somalia',
    pop2022: '17597.5110',
    pop2021: '17065.5810',
    growthRate: '1.0312',
    area: 637657,
    density: '27.5971',
  },
  {
    rank: 72,
    name: 'Netherlands',
    pop2022: '17564.0140',
    pop2021: '17501.6960',
    growthRate: '1.0036',
    area: 41850,
    density: '419.6897',
  },
  {
    rank: 73,
    name: 'Senegal',
    pop2022: '17316.4490',
    pop2021: '16876.7200',
    growthRate: '1.0261',
    area: 196722,
    density: '88.0250',
  },
  {
    rank: 74,
    name: 'Cambodia',
    pop2022: '16767.8420',
    pop2021: '16589.0230',
    growthRate: '1.0108',
    area: 181035,
    density: '92.6221',
  },
  {
    rank: 75,
    name: 'Zimbabwe',
    pop2022: '16320.5370',
    pop2021: '15993.5240',
    growthRate: '1.0204',
    area: 390757,
    density: '41.7665',
  },
  {
    rank: 76,
    name: 'Guinea',
    pop2022: '13859.3410',
    pop2021: '13531.9060',
    growthRate: '1.0242',
    area: 245857,
    density: '56.3716',
  },
  {
    rank: 77,
    name: 'Rwanda',
    pop2022: '13776.6980',
    pop2021: '13461.8880',
    growthRate: '1.0234',
    area: 26338,
    density: '523.0731',
  },
  {
    rank: 78,
    name: 'Benin',
    pop2022: '13352.8640',
    pop2021: '12996.8950',
    growthRate: '1.0274',
    area: 112622,
    density: '118.5635',
  },
  {
    rank: 79,
    name: 'Burundi',
    pop2022: '12889.5760',
    pop2021: '12551.2130',
    growthRate: '1.0270',
    area: 27834,
    density: '463.0874',
  },
  {
    rank: 80,
    name: 'Tunisia',
    pop2022: '12356.1170',
    pop2021: '12262.9460',
    growthRate: '1.0076',
    area: 163610,
    density: '75.5218',
  },
  {
    rank: 81,
    name: 'Bolivia',
    pop2022: '12224.1100',
    pop2021: '12079.4720',
    growthRate: '1.0120',
    area: 1098581,
    density: '11.1272',
  },
  {
    rank: 82,
    name: 'Belgium',
    pop2022: '11655.9300',
    pop2021: '11611.4190',
    growthRate: '1.0038',
    area: 30528,
    density: '381.8111',
  },
  {
    rank: 83,
    name: 'Haiti',
    pop2022: '11584.9960',
    pop2021: '11447.5690',
    growthRate: '1.0120',
    area: 27750,
    density: '417.4773',
  },
  {
    rank: 84,
    name: 'Jordan',
    pop2022: '11285.8690',
    pop2021: '11148.2780',
    growthRate: '1.0123',
    area: 89342,
    density: '126.3221',
  },
  {
    rank: 85,
    name: 'Dominican Republic',
    pop2022: '11228.8210',
    pop2021: '11117.8730',
    growthRate: '1.0100',
    area: 48671,
    density: '230.7087',
  },
  {
    rank: 86,
    name: 'Cuba',
    pop2022: '11212.1910',
    pop2021: '11256.3720',
    growthRate: '0.9961',
    area: 109884,
    density: '102.0366',
  },
  {
    rank: 87,
    name: 'South Sudan',
    pop2022: '10913.1640',
    pop2021: '10748.2720',
    growthRate: '1.0153',
    area: 619745,
    density: '17.6091',
  },
  {
    rank: 88,
    name: 'Sweden',
    pop2022: '10549.3470',
    pop2021: '10467.0970',
    growthRate: '1.0079',
    area: 450295,
    density: '23.4276',
  },
  {
    rank: 89,
    name: 'Czech Republic',
    pop2022: '10493.9860',
    pop2021: '10510.7510',
    growthRate: '0.9984',
    area: 78865,
    density: '133.0627',
  },
  {
    rank: 90,
    name: 'Honduras',
    pop2022: '10432.8600',
    pop2021: '10278.3450',
    growthRate: '1.0150',
    area: 112492,
    density: '92.7431',
  },
  {
    rank: 91,
    name: 'Greece',
    pop2022: '10384.9710',
    pop2021: '10445.3650',
    growthRate: '0.9942',
    area: 131990,
    density: '78.6800',
  },
  {
    rank: 92,
    name: 'Azerbaijan',
    pop2022: '10358.0740',
    pop2021: '10312.9920',
    growthRate: '1.0044',
    area: 86600,
    density: '119.6082',
  },
  {
    rank: 93,
    name: 'Portugal',
    pop2022: '10270.8650',
    pop2021: '10290.1030',
    growthRate: '0.9981',
    area: 92090,
    density: '111.5307',
  },
  {
    rank: 94,
    name: 'Papua New Guinea',
    pop2022: '10142.6190',
    pop2021: '9949.4370',
    growthRate: '1.0194',
    area: 462840,
    density: '21.9139',
  },
  {
    rank: 95,
    name: 'Hungary',
    pop2022: '9967.3080',
    pop2021: '9709.7860',
    growthRate: '1.0265',
    area: 93028,
    density: '107.1431',
  },
  {
    rank: 96,
    name: 'Tajikistan',
    pop2022: '9952.7870',
    pop2021: '9750.0640',
    growthRate: '1.0208',
    area: 143100,
    density: '69.5513',
  },
  {
    rank: 97,
    name: 'Belarus',
    pop2022: '9534.9540',
    pop2021: '9578.1670',
    growthRate: '0.9955',
    area: 207600,
    density: '45.9295',
  },
  {
    rank: 98,
    name: 'United Arab Emirates',
    pop2022: '9441.1290',
    pop2021: '9365.1450',
    growthRate: '1.0081',
    area: 83600,
    density: '112.9322',
  },
  {
    rank: 99,
    name: 'Israel',
    pop2022: '9038.3090',
    pop2021: '8900.0590',
    growthRate: '1.0155',
    area: 20770,
    density: '435.1617',
  },
  {
    rank: 100,
    name: 'Austria',
    pop2022: '8939.6170',
    pop2021: '8922.0820',
    growthRate: '1.0020',
    area: 83871,
    density: '106.5877',
  },
  {
    rank: 101,
    name: 'Togo',
    pop2022: '8848.6990',
    pop2021: '8644.8290',
    growthRate: '1.0236',
    area: 56785,
    density: '155.8281',
  },
  {
    rank: 102,
    name: 'Switzerland',
    pop2022: '8740.4720',
    pop2021: '8691.4060',
    growthRate: '1.0056',
    area: 41284,
    density: '211.7157',
  },
  {
    rank: 103,
    name: 'Sierra Leone',
    pop2022: '8605.7180',
    pop2021: '8420.6410',
    growthRate: '1.0220',
    area: 71740,
    density: '119.9570',
  },
  {
    rank: 104,
    name: 'Laos',
    pop2022: '7529.4750',
    pop2021: '7425.0570',
    growthRate: '1.0141',
    area: 236800,
    density: '31.7968',
  },
  {
    rank: 105,
    name: 'Hong Kong',
    pop2022: '7488.8650',
    pop2021: '7494.5780',
    growthRate: '0.9992',
    area: 1104,
    density: '6783.3922',
  },
  {
    rank: 106,
    name: 'Serbia',
    pop2022: '7221.3650',
    pop2021: '7296.7690',
    growthRate: '0.9897',
    area: 88361,
    density: '81.7257',
  },
  {
    rank: 107,
    name: 'Nicaragua',
    pop2022: '6948.3920',
    pop2021: '6850.5400',
    growthRate: '1.0143',
    area: 130373,
    density: '53.2962',
  },
  {
    rank: 108,
    name: 'Libya',
    pop2022: '6812.3410',
    pop2021: '6735.2770',
    growthRate: '1.0114',
    area: 1759540,
    density: '3.8717',
  },
  {
    rank: 109,
    name: 'Bulgaria',
    pop2022: '6781.9530',
    pop2021: '6885.8680',
    growthRate: '0.9849',
    area: 110879,
    density: '61.1654',
  },
  {
    rank: 110,
    name: 'Paraguay',
    pop2022: '6780.7440',
    pop2021: '6703.7990',
    growthRate: '1.0115',
    area: 406752,
    density: '16.6705',
  },
  {
    rank: 111,
    name: 'Kyrgyzstan',
    pop2022: '6630.6230',
    pop2021: '6527.7430',
    growthRate: '1.0158',
    area: 199951,
    density: '33.1612',
  },
  {
    rank: 112,
    name: 'Turkmenistan',
    pop2022: '6430.7700',
    pop2021: '6341.8550',
    growthRate: '1.0140',
    area: 488100,
    density: '13.1751',
  },
  {
    rank: 113,
    name: 'El Salvador',
    pop2022: '6336.3920',
    pop2021: '6314.1670',
    growthRate: '1.0035',
    area: 21041,
    density: '301.1450',
  },
  {
    rank: 114,
    name: 'Singapore',
    pop2022: '5975.6890',
    pop2021: '5941.0600',
    growthRate: '1.0058',
    area: 710,
    density: '8416.4634',
  },
  {
    rank: 115,
    name: 'Republic of the Congo',
    pop2022: '5970.4240',
    pop2021: '5835.8060',
    growthRate: '1.0231',
    area: 342000,
    density: '17.4574',
  },
  {
    rank: 116,
    name: 'Denmark',
    pop2022: '5882.2610',
    pop2021: '5854.2400',
    growthRate: '1.0048',
    area: 43094,
    density: '136.4984',
  },
  {
    rank: 117,
    name: 'Slovakia',
    pop2022: '5643.4530',
    pop2021: '5447.6220',
    growthRate: '1.0359',
    area: 49037,
    density: '115.0856',
  },
  {
    rank: 118,
    name: 'Central African Republic',
    pop2022: '5579.1440',
    pop2021: '5457.1540',
    growthRate: '1.0224',
    area: 622984,
    density: '8.9555',
  },
  {
    rank: 119,
    name: 'Finland',
    pop2022: '5540.7450',
    pop2021: '5535.9920',
    growthRate: '1.0009',
    area: 338424,
    density: '16.3722',
  },
  {
    rank: 120,
    name: 'Scotland',
    pop2022: '5504.6507',
    growthRate: '1.0035',
    area: 77933,
    density: '70.6331',
  },
  {
    rank: 121,
    name: 'Lebanon',
    pop2022: '5489.7390',
    pop2021: '5592.6310',
    growthRate: '0.9816',
    area: 10452,
    density: '525.2334',
  },
  {
    rank: 122,
    name: 'Norway',
    pop2022: '5434.3190',
    pop2021: '5403.0210',
    growthRate: '1.0058',
    area: 323802,
    density: '16.7828',
  },
  {
    rank: 123,
    name: 'Liberia',
    pop2022: '5302.6810',
    pop2021: '5193.4160',
    growthRate: '1.0210',
    area: 111369,
    density: '47.6136',
  },
  {
    rank: 124,
    name: 'Palestine',
    pop2022: '5250.0720',
    pop2021: '5133.3920',
    growthRate: '1.0227',
    area: 6220,
    density: '844.0630',
  },
  {
    rank: 125,
    name: 'New Zealand',
    pop2022: '5185.2880',
    pop2021: '5129.7270',
    growthRate: '1.0108',
    area: 270467,
    density: '19.1716',
  },
  {
    rank: 126,
    name: 'Costa Rica',
    pop2022: '5180.8290',
    pop2021: '5153.9570',
    growthRate: '1.0052',
    area: 51100,
    density: '101.3861',
  },
  {
    rank: 127,
    name: 'Ireland',
    pop2022: '5023.1090',
    pop2021: '4986.5260',
    growthRate: '1.0073',
    area: 70273,
    density: '71.4799',
  },
  {
    rank: 128,
    name: 'Mauritania',
    pop2022: '4736.1390',
    pop2021: '4614.9740',
    growthRate: '1.0263',
    area: 1030700,
    density: '4.5951',
  },
  {
    rank: 129,
    name: 'Oman',
    pop2022: '4576.2980',
    pop2021: '4520.4710',
    growthRate: '1.0123',
    area: 309500,
    density: '14.7861',
  },
  {
    rank: 130,
    name: 'Panama',
    pop2022: '4408.5810',
    pop2021: '4351.2670',
    growthRate: '1.0132',
    area: 75417,
    density: '58.4561',
  },
  {
    rank: 131,
    name: 'Kuwait',
    pop2022: '4268.8730',
    pop2021: '4250.1140',
    growthRate: '1.0044',
    area: 17818,
    density: '239.5821',
  },
  {
    rank: 132,
    name: 'Croatia',
    pop2022: '4030.3580',
    pop2021: '4060.1350',
    growthRate: '0.9927',
    area: 56594,
    density: '71.2153',
  },
  {
    rank: 133,
    name: 'Georgia',
    pop2022: '3744.3850',
    pop2021: '3757.9800',
    growthRate: '0.9964',
    area: 69700,
    density: '53.7214',
  },
  {
    rank: 134,
    name: 'Eritrea',
    pop2022: '3684.0320',
    pop2021: '3620.3120',
    growthRate: '1.0176',
    area: 117600,
    density: '31.3268',
  },
  {
    rank: 135,
    name: 'Uruguay',
    pop2022: '3422.7940',
    pop2021: '3426.2600',
    growthRate: '0.9990',
    area: 181034,
    density: '18.9069',
  },
  {
    rank: 136,
    name: 'Mongolia',
    pop2022: '3398.3660',
    pop2021: '3347.7820',
    growthRate: '1.0151',
    area: 1564110,
    density: '2.1727',
  },
  {
    rank: 137,
    name: 'Moldova',
    pop2022: '3272.9960',
    pop2021: '3061.5060',
    growthRate: '1.0691',
    area: 33846,
    density: '96.7026',
  },
  {
    rank: 138,
    name: 'Puerto Rico',
    pop2022: '3252.4070',
    pop2021: '3256.0280',
    growthRate: '0.9989',
    area: 8870,
    density: '366.6750',
  },
  {
    rank: 139,
    name: 'Bosnia and Herzegovina',
    pop2022: '3233.5260',
    pop2021: '3270.9430',
    growthRate: '0.9886',
    area: 51209,
    density: '63.1437',
  },
  {
    rank: 140,
    name: 'Wales',
    pop2022: '3193.6653',
    growthRate: '1.0038',
    area: 20779,
    density: '153.6968',
  },
  {
    rank: 141,
    name: 'Albania',
    pop2022: '2842.3210',
    pop2021: '2854.7100',
    growthRate: '0.9957',
    area: 28748,
    density: '98.8702',
  },
  {
    rank: 142,
    name: 'Jamaica',
    pop2022: '2827.3770',
    pop2021: '2827.6950',
    growthRate: '0.9999',
    area: 10991,
    density: '257.2447',
  },
  {
    rank: 143,
    name: 'Armenia',
    pop2022: '2780.4690',
    pop2021: '2790.9740',
    growthRate: '0.9962',
    area: 29743,
    density: '93.4831',
  },
  {
    rank: 144,
    name: 'Lithuania',
    pop2022: '2750.0550',
    pop2021: '2786.6510',
    growthRate: '0.9869',
    area: 65300,
    density: '42.1142',
  },
  {
    rank: 145,
    name: 'Gambia',
    pop2022: '2705.9920',
    pop2021: '2639.9160',
    growthRate: '1.0250',
    area: 10689,
    density: '253.1567',
  },
  {
    rank: 146,
    name: 'Qatar',
    pop2022: '2695.1220',
    pop2021: '2688.2350',
    growthRate: '1.0026',
    area: 11586,
    density: '232.6189',
  },
  {
    rank: 147,
    name: 'Botswana',
    pop2022: '2630.2960',
    pop2021: '2588.4230',
    growthRate: '1.0162',
    area: 582000,
    density: '4.5194',
  },
  {
    rank: 148,
    name: 'Namibia',
    pop2022: '2567.0120',
    pop2021: '2530.1510',
    growthRate: '1.0146',
    area: 825615,
    density: '3.1092',
  },
  {
    rank: 149,
    name: 'Gabon',
    pop2022: '2388.9920',
    pop2021: '2341.1790',
    growthRate: '1.0204',
    area: 267668,
    density: '8.9252',
  },
  {
    rank: 150,
    name: 'Lesotho',
    pop2022: '2305.8250',
    pop2021: '2281.4540',
    growthRate: '1.0107',
    area: 30355,
    density: '75.9620',
  },
  {
    rank: 151,
    name: 'Slovenia',
    pop2022: '2119.8440',
    pop2021: '2119.4100',
    growthRate: '1.0002',
    area: 20273,
    density: '104.5649',
  },
  {
    rank: 152,
    name: 'Guinea-Bissau',
    pop2022: '2105.5660',
    pop2021: '2060.7210',
    growthRate: '1.0218',
    area: 36125,
    density: '58.2856',
  },
  {
    rank: 153,
    name: 'North Macedonia',
    pop2022: '2093.5990',
    pop2021: '2103.3300',
    growthRate: '0.9954',
    area: 25713,
    density: '81.4218',
  },
  {
    rank: 154,
    name: 'Latvia',
    pop2022: '1850.6510',
    pop2021: '1873.9190',
    growthRate: '0.9876',
    area: 64559,
    density: '28.6660',
  },
  {
    rank: 155,
    name: 'Kosovo',
    pop2022: '1802.2502',
    growthRate: '1.0011',
    area: 10887,
    density: '165.5415',
  },
  {
    rank: 156,
    name: 'Equatorial Guinea',
    pop2022: '1674.9080',
    pop2021: '1634.4660',
    growthRate: '1.0247',
    area: 28051,
    density: '59.7094',
  },
  {
    rank: 157,
    name: 'Trinidad and Tobago',
    pop2022: '1531.0440',
    pop2021: '1525.6630',
    growthRate: '1.0035',
    area: 5130,
    density: '298.4491',
  },
  {
    rank: 158,
    name: 'Bahrain',
    pop2022: '1472.2330',
    pop2021: '1463.2650',
    growthRate: '1.0061',
    area: 765,
    density: '1924.4876',
  },
  {
    rank: 159,
    name: 'Timor-Leste',
    pop2022: '1341.2960',
    pop2021: '1320.9420',
    growthRate: '1.0154',
    area: 14874,
    density: '90.1772',
  },
  {
    rank: 160,
    name: 'Estonia',
    pop2022: '1326.0620',
    pop2021: '1328.7010',
    growthRate: '0.9980',
    area: 45227,
    density: '29.3201',
  },
  {
    rank: 161,
    name: 'Mauritius',
    pop2022: '1299.4690',
    pop2021: '1298.9150',
    growthRate: '1.0004',
    area: 2040,
    density: '636.9946',
  },
  {
    rank: 162,
    name: 'Cyprus',
    pop2022: '1251.4880',
    pop2021: '1244.1880',
    growthRate: '1.0059',
    area: 9251,
    density: '135.2814',
  },
  {
    rank: 163,
    name: 'Eswatini',
    pop2022: '1201.6700',
    pop2021: '1192.2710',
    growthRate: '1.0079',
    area: 17364,
    density: '69.2047',
  },
  {
    rank: 164,
    name: 'Djibouti',
    pop2022: '1120.8490',
    pop2021: '1105.5570',
    growthRate: '1.0138',
    area: 23200,
    density: '48.3125',
  },
  {
    rank: 165,
    name: 'Reunion',
    pop2022: '974.0520',
    pop2021: '966.1290',
    growthRate: '1.0082',
    area: 2511,
    density: '387.9140',
  },
  {
    rank: 166,
    name: 'Fiji',
    pop2022: '929.7660',
    pop2021: '924.6100',
    growthRate: '1.0056',
    area: 18272,
    density: '50.8847',
  },
  {
    rank: 167,
    name: 'Comoros',
    pop2022: '836.7740',
    pop2021: '821.6250',
    growthRate: '1.0184',
    area: 1862,
    density: '449.3953',
  },
  {
    rank: 168,
    name: 'Guyana',
    pop2022: '808.7260',
    pop2021: '804.5670',
    growthRate: '1.0052',
    area: 214969,
    density: '3.7621',
  },
  {
    rank: 169,
    name: 'Bhutan',
    pop2022: '782.4550',
    pop2021: '777.4860',
    growthRate: '1.0064',
    area: 38394,
    density: '20.3796',
  },
  {
    rank: 170,
    name: 'Solomon Islands',
    pop2022: '724.2730',
    pop2021: '707.8510',
    growthRate: '1.0232',
    area: 28896,
    density: '25.0648',
  },
  {
    rank: 171,
    name: 'Luxembourg',
    pop2022: '647.5990',
    pop2021: '639.3210',
    growthRate: '1.0129',
    area: 2586,
    density: '250.4250',
  },
  {
    rank: 172,
    name: 'Montenegro',
    pop2022: '627.0820',
    pop2021: '627.8590',
    growthRate: '0.9988',
    area: 13812,
    density: '45.4012',
  },
  {
    rank: 173,
    name: 'Suriname',
    pop2022: '618.0400',
    pop2021: '612.9850',
    growthRate: '1.0082',
    area: 163820,
    density: '3.7727',
  },
  {
    rank: 174,
    name: 'Cape Verde',
    pop2022: '593.1490',
    pop2021: '587.9250',
    growthRate: '1.0089',
    area: 4033,
    density: '147.0739',
  },
  {
    rank: 175,
    name: 'Western Sahara',
    pop2022: '575.9860',
    pop2021: '565.5810',
    growthRate: '1.0184',
    area: 266000,
    density: '2.1654',
  },
  {
    rank: 176,
    name: 'Malta',
    pop2022: '533.2860',
    pop2021: '526.7480',
    growthRate: '1.0124',
    area: 316,
    density: '1687.6139',
  },
  {
    rank: 177,
    name: 'Maldives',
    pop2022: '523.7870',
    pop2021: '521.4570',
    growthRate: '1.0045',
    area: 300,
    density: '1745.9567',
  },
  {
    rank: 178,
    name: 'Brunei',
    pop2022: '449.0020',
    pop2021: '445.3730',
    growthRate: '1.0081',
    area: 5765,
    density: '77.8841',
  },
  {
    rank: 179,
    name: 'Bahamas',
    pop2022: '409.9840',
    pop2021: '407.9060',
    growthRate: '1.0051',
    area: 13943,
    density: '29.4043',
  },
  {
    rank: 180,
    name: 'Belize',
    pop2022: '405.2720',
    pop2021: '400.0310',
    growthRate: '1.0131',
    area: 22966,
    density: '17.6466',
  },
  {
    rank: 181,
    name: 'Guadeloupe',
    pop2022: '395.7520',
    pop2021: '396.0510',
    growthRate: '0.9992',
    area: 1628,
    density: '243.0909',
  },
  {
    rank: 182,
    name: 'Iceland',
    pop2022: '372.8990',
    pop2021: '370.3350',
    growthRate: '1.0069',
    area: 103000,
    density: '3.6204',
  },
  {
    rank: 183,
    name: 'Martinique',
    pop2022: '367.5070',
    pop2021: '368.7960',
    growthRate: '0.9965',
    area: 1128,
    density: '325.8041',
  },
  {
    rank: 184,
    name: 'Vanuatu',
    pop2022: '326.7400',
    pop2021: '319.1370',
    growthRate: '1.0238',
    area: 12189,
    density: '26.8061',
  },
  {
    rank: 185,
    name: 'Mayotte',
    pop2022: '326.1010',
    pop2021: '316.0140',
    growthRate: '1.0319',
    area: 374,
    density: '871.9278',
  },
  {
    rank: 186,
    name: 'French Guiana',
    pop2022: '304.5570',
    pop2021: '297.4490',
    growthRate: '1.0239',
    area: 83534,
    density: '3.6459',
  },
  {
    rank: 187,
    name: 'Barbados',
    pop2022: '281.6350',
    pop2021: '281.2000',
    growthRate: '1.0015',
    area: 430,
    density: '654.9651',
  },
  {
    rank: 188,
    name: 'Sao Tome and Principe',
    pop2022: '227.3800',
    pop2021: '223.1070',
    growthRate: '1.0192',
    area: 964,
    density: '235.8714',
  },
  {
    rank: 189,
    name: 'Samoa',
    pop2022: '222.3820',
    pop2021: '218.7640',
    growthRate: '1.0165',
    area: 2842,
    density: '78.2484',
  },
  {
    rank: 190,
    name: 'Saint Lucia',
    pop2022: '179.8570',
    pop2021: '179.6510',
    growthRate: '1.0011',
    area: 616,
    density: '291.9756',
  },
  {
    rank: 191,
    name: 'Kiribati',
    pop2022: '131.2320',
    pop2021: '128.8740',
    growthRate: '1.0183',
    area: 811,
    density: '161.8150',
  },
  {
    rank: 192,
    name: 'Grenada',
    pop2022: '125.4380',
    pop2021: '124.6100',
    growthRate: '1.0066',
    area: 344,
    density: '364.6453',
  },
  {
    rank: 193,
    name: 'Micronesia',
    pop2022: '114.1640',
    pop2021: '113.1310',
    growthRate: '1.0091',
    area: 702,
    density: '162.6268',
  },
  {
    rank: 194,
    name: 'Seychelles',
    pop2022: '107.1180',
    pop2021: '106.4710',
    growthRate: '1.0061',
    area: 452,
    density: '236.9867',
  },
  {
    rank: 195,
    name: 'Tonga',
    pop2022: '106.8580',
    pop2021: '106.0170',
    growthRate: '1.0079',
    area: 747,
    density: '143.0495',
  },
  {
    rank: 196,
    name: 'Saint Vincent and the Grenadines',
    pop2022: '103.9480',
    pop2021: '104.3320',
    growthRate: '0.9963',
    area: 389,
    density: '267.2185',
  },
  {
    rank: 197,
    name: 'Antigua and Barbuda',
    pop2022: '93.7630',
    pop2021: '93.2190',
    growthRate: '1.0058',
    area: 442,
    density: '212.1335',
  },
  {
    rank: 198,
    name: 'Andorra',
    pop2022: '79.8240',
    pop2021: '79.0340',
    growthRate: '1.0100',
    area: 468,
    density: '170.5641',
  },
  {
    rank: 199,
    name: 'Dominica',
    pop2022: '72.7370',
    pop2021: '72.4120',
    growthRate: '1.0045',
    area: 751,
    density: '96.8535',
  },
  {
    rank: 200,
    name: 'Greenland',
    pop2022: '56.4660',
    pop2021: '56.2430',
    growthRate: '1.0040',
    area: 2166086,
    density: '0.0261',
  },
  {
    rank: 201,
    name: 'Saint Kitts and Nevis',
    pop2022: '47.6570',
    pop2021: '47.6060',
    growthRate: '1.0011',
    area: 261,
    density: '182.5939',
  },
  {
    rank: 202,
    name: 'Marshall Islands',
    pop2022: '41.5690',
    pop2021: '42.0500',
    growthRate: '0.9886',
    area: 181,
    density: '229.6630',
  },
  {
    rank: 203,
    name: 'Liechtenstein',
    pop2022: '39.3270',
    pop2021: '39.0390',
    growthRate: '1.0074',
    area: 160,
    density: '245.7937',
  },
  {
    rank: 204,
    name: 'Monaco',
    pop2022: '36.4690',
    pop2021: '36.6860',
    growthRate: '0.9941',
    area: 2,
    density: '18234.5000',
  },
  {
    rank: 205,
    name: 'San Marino',
    pop2022: '33.6600',
    pop2021: '33.7450',
    growthRate: '0.9975',
    area: 61,
    density: '551.8033',
  },
  {
    rank: 206,
    name: 'Palau',
    pop2022: '18.0550',
    pop2021: '18.0240',
    growthRate: '1.0017',
    area: 459,
    density: '39.3355',
  },
  {
    rank: 207,
    name: 'Nauru',
    pop2022: '12.6680',
    pop2021: '12.5110',
    growthRate: '1.0125',
    area: 21,
    density: '603.2381',
  },
  {
    rank: 208,
    name: 'Tuvalu',
    pop2022: '11.3120',
    pop2021: '11.2040',
    growthRate: '1.0096',
    area: 26,
    density: '435.0769',
  },
  {
    rank: 209,
    name: 'Vatican City',
    pop2022: '0.5100',
    pop2021: '0.5110',
    growthRate: '0.9980',
    area: 1,
    density: '0.510',
  },
];
