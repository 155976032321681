import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";

// Using strict mode throws a massive warning in the console because reactstrap uses an outdate context api
// https://github.com/reactstrap/reactstrap/issues/1340
// The workaround is to replace `React.StrictMode` with `React.Fragment`
// When the issue is fixed we can change it back to `React.StrictMode`
ReactDOM.render(
  <React.Fragment>
    <Router>
      <App />
    </Router>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
