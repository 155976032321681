import { useState, useEffect } from 'react';
import { countryNames } from '@data';
import './countries.page.scss';

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVYZ'.split('');

export const Countries = () => {
  const [inputValue, setInputValue] = useState('');
  const [guessedCountries, setGuessedCountries] = useState<string[]>([]);
  const [revealedCountries, setRevealedCountries] = useState<string[]>([]);
  const [gaveUp, setGaveUp] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<number>(15 * 60); // 15 minutes in seconds

  useEffect(() => {
    if (timeLeft > 0 && !gaveUp) {
      const timer = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (timeLeft === 0) {
      handleGiveUp();
    }
  }, [timeLeft, gaveUp]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.toUpperCase();
    setInputValue(input);

    const matchedCountry = countryNames.find(
      (country: { name: string; aliases: string[] }) =>
        country.name.toUpperCase() === input ||
        country.aliases.some((alias) => alias.toUpperCase() === input)
    );

    if (matchedCountry && !guessedCountries.includes(matchedCountry.name)) {
      setGuessedCountries([...guessedCountries, matchedCountry.name]);
      setInputValue(''); // Clear the input
    }
  };

  const handleGiveUp = () => {
    setRevealedCountries(countryNames.map((country) => country.name));
    setGaveUp(true);
  };

  const handleRestart = () => {
    setGuessedCountries([]);
    setRevealedCountries([]);
    setGaveUp(false);
    setInputValue('');
    setTimeLeft(15 * 60); // Reset the timer to 15 minutes
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <div className="quiz-container">
      <div className="input-group">
        <div className="timer">Time left: {formatTime(timeLeft)}</div>
        <form onSubmit={(e) => e.preventDefault()} className="input-form">
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Enter country name..."
            disabled={gaveUp}
            className="neumorphism-input"
          />
          {!gaveUp ? (
            <button
              type="button"
              onClick={handleGiveUp}
              className="country-quiz-button"
            >
              Give Up
            </button>
          ) : (
            <button
              type="button"
              onClick={handleRestart}
              className="country-quiz-button"
            >
              Restart
            </button>
          )}
        </form>
      </div>
      <div className="countries-list">
        {alphabet.map((letter) => (
          <div key={letter} className="letter-section">
            <h2>{letter}</h2>
            <ul>
              {countryNames
                .filter((country) => country.name.startsWith(letter))
                .map((country) => (
                  <li
                    key={country.name}
                    className={
                      guessedCountries.includes(country.name)
                        ? 'guessed'
                        : revealedCountries.includes(country.name)
                        ? 'not-guessed'
                        : 'not-revealed'
                    }
                  >
                    {guessedCountries.includes(country.name) ||
                    revealedCountries.includes(country.name)
                      ? country.name
                      : '______'}
                  </li>
                ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};
