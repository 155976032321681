import { useState } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import trophyLogo from '../../../trophy.svg';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { ROUTES } from '@routes';

export const Header = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Navbar color="dark" dark expand="md">
      <NavbarBrand href="/">
        <img
          style={{ width: '30px' }}
          src={trophyLogo}
          alt="trivia-resources-logo"
          className="d-inline-block align-top"
        />{' '}
        Trivia Resources
      </NavbarBrand>
      <NavbarToggler onClick={toggle} className="mr-2" />
      <Collapse isOpen={isOpen} navbar>
        <Nav>
          {ROUTES.map(({ path, title }) => (
            <NavItem key={path}>
              <NavLink tag={RRNavLink} to={path}>
                {title}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </Collapse>
    </Navbar>
  );
};
