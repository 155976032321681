import { useState } from "react";
import { Col, Container, Row } from 'reactstrap';
import { DragDropContext, Droppable, Draggable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { countryService } from '@services';
import './population-ordering.page.scss';
import { OldCountry } from "@models";

const CountryList = ({ countries }: { countries: OldCountry[] }) => {
  return (
    <>
      {countries.map(({ name, pop2022 }, index) => (
        <Draggable key={name} draggableId={name} index={index}>
          {(provided) => (
            <Row key={index}>
              <Col>
                <article
                  className="country"
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <h3>{name}</h3>
                  <p>{pop2022}</p>
                </article>
              </Col>
            </Row>
          )}
        </Draggable>
      ))}
    </>
  );
};

export const PopulationOrdering = () => {
  const [countries, setCountries] = useState(countryService.getRandomCountries(8));

  const reorder = (list: OldCountry[], startIndex:number, endIndex:number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };
  

  const onDragEnd = (result: DropResult, _provided: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newCountries = reorder(
      countries,
      result.source.index,
      result.destination.index
    );

    setCountries(newCountries);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <Container className="country-list" fluid="md">
              <h1>Population Order</h1>
              <CountryList countries={countries} />
              {provided.placeholder}
            </Container>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
