import { shuffle } from "lodash";
import { Question, OpentdbSet } from "@models";

class OpenTdb {
  getSingleQuestion = (): Promise<Question[]> => {
    const url = "https://opentdb.com/api.php?amount=1&category=9&type=multiple";

    return fetch(url)
      .then((data) => data.json())
      .then((result) => result.results)
      .catch((error) => {
        console.warn(error);
        return null;
      });
  };

  getArrayOfQuestions = (numberOfQuestions = 50): Promise<OpentdbSet[]> => {
    // Open Trivia DB only returns a max of 50 questions per request.
    if (numberOfQuestions > 50) {
      numberOfQuestions = 50;
    }

    const url = `https://opentdb.com/api.php?amount=${numberOfQuestions}&type=multiple`;

    return fetch(url)
      .then((data) => data.json())
      .then((result) => result.results)
      .catch((error) => {
        console.warn(error);
        return null;
      });
  };

  getTriviaRounds = async (rounds?: number): Promise<Question[]> => {
    const result = await this.getArrayOfQuestions(rounds);

    const questions = result.map(
      ({ incorrect_answers, correct_answer, ...rest }) => {
        incorrect_answers.push(correct_answer);

        return {
          answer: correct_answer,
          options: shuffle(incorrect_answers),
          ...rest,
        };
      }
    );
    return questions;
  };
}

export const opentdbService = new OpenTdb();
