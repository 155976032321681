import shuffle from "lodash/shuffle";
import chunk from "lodash/chunk";
import { flags } from "@data";
import { Flag } from "@models";

class FlagService {
  /**
   *
   * @param options
   * @returns A chunked array of rounds. e.g 10 arrays of 5 flag options.
   */
  getFlags = (options: { numRounds: number; numOptions: number }): Flag[][] => {
    const { numRounds, numOptions } = options;

    // Shuffle all the flags
    // Slice off the required number of flags
    // Chunk them into arrays of flags
    const requiredFlags = shuffle(flags).slice(0, numRounds * numOptions);

    return chunk(requiredFlags, 5);
  };
}

export const flagService = new FlagService();
