import { Flag } from "@models";

export const flags: Flag[] = [
  {
    country: "Afghanistan",
    fileName: "Flag_of_Afghanistan.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Afghanistan.svg",
  },
  {
    country: "Albania",
    fileName: "Flag_of_Albania.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/3/36/Flag_of_Albania.svg",
  },
  {
    country: "Algeria",
    fileName: "Flag_of_Algeria.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Algeria.svg",
  },
  {
    country: "Andorra",
    fileName: "Flag_of_Andorra.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Andorra.svg",
  },
  {
    country: "Angola",
    fileName: "Flag_of_Angola.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Angola.svg",
  },
  {
    country: "Antigua and Barbuda",
    fileName: "Flag_of_Antigua_and_Barbuda.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Antigua_and_Barbuda.svg",
  },
  {
    country: "Argentina",
    fileName: "Flag_of_Argentina.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_Argentina.svg",
  },
  {
    country: "Armenia",
    fileName: "Flag_of_Armenia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/2/2f/Flag_of_Armenia.svg",
  },
  {
    country: "Australia",
    fileName: "Flag_of_Australia_%28converted%29.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_%28converted%29.svg",
  },
  {
    country: "Austria",
    fileName: "Flag_of_Austria.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_Austria.svg",
  },
  {
    country: "Azerbaijan",
    fileName: "Flag_of_Azerbaijan.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Azerbaijan.svg",
  },
  {
    country: "Bahamas",
    fileName: "Flag_of_the_Bahamas.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/9/93/Flag_of_the_Bahamas.svg",
  },
  {
    country: "Bahrain",
    fileName: "Flag_of_Bahrain.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Bahrain.svg",
  },
  {
    country: "Bangladesh",
    fileName: "Flag_of_Bangladesh.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/f/f9/Flag_of_Bangladesh.svg",
  },
  {
    country: "Barbados",
    fileName: "Flag_of_Barbados.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/e/ef/Flag_of_Barbados.svg",
  },
  {
    country: "Belarus",
    fileName: "Flag_of_Belarus.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/8/85/Flag_of_Belarus.svg",
  },
  {
    country: "Belgium",
    fileName: "Flag_of_Belgium_%28civil%29.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_Belgium_%28civil%29.svg",
  },
  {
    country: "Belize",
    fileName: "Flag_of_Belize.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/e/e7/Flag_of_Belize.svg",
  },
  {
    country: "Benin",
    fileName: "Flag_of_Benin.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Benin.svg",
  },
  {
    country: "Bhutan",
    fileName: "Flag_of_Bhutan.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/9/91/Flag_of_Bhutan.svg",
  },
  {
    country: "Bolivia",
    fileName: "Flag_of_Bolivia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Bolivia.svg",
  },
  {
    country: "Bosnia and Herzegovina",
    fileName: "Flag_of_Bosnia_and_Herzegovina.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bosnia_and_Herzegovina.svg",
  },
  {
    country: "Botswana",
    fileName: "Flag_of_Botswana.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Botswana.svg",
  },
  {
    country: "Brazil",
    fileName: "Flag_of_Brazil.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/en/0/05/Flag_of_Brazil.svg",
  },
  {
    country: "Brunei",
    fileName: "Flag_of_Brunei.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Brunei.svg",
  },
  {
    country: "Bulgaria",
    fileName: "Flag_of_Bulgaria.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Bulgaria.svg",
  },
  {
    country: "Burkina Faso",
    fileName: "Flag_of_Burkina_Faso.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Burkina_Faso.svg",
  },
  {
    country: "Burundi",
    fileName: "Flag_of_Burundi.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/5/50/Flag_of_Burundi.svg",
  },
  {
    country: "Cambodia",
    fileName: "Flag_of_Cambodia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_Cambodia.svg",
  },
  {
    country: "Cameroon",
    fileName: "Flag_of_Cameroon.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Cameroon.svg",
  },
  {
    country: "Canada",
    fileName: "Flag_of_Canada.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/en/c/cf/Flag_of_Canada.svg",
  },
  {
    country: "Cape Verde",
    fileName: "Flag_of_Cape_Verde.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Cape_Verde.svg",
  },
  {
    country: "Central African Republic",
    fileName: "Flag_of_the_Central_African_Republic.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/6/6f/Flag_of_the_Central_African_Republic.svg",
  },
  {
    country: "Chad",
    fileName: "Flag_of_Chad.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/4/4b/Flag_of_Chad.svg",
  },
  {
    country: "Chile",
    fileName: "Flag_of_Chile.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/7/78/Flag_of_Chile.svg",
  },
  {
    country: "China",
    fileName: "Flag_of_the_People%27s_Republic_of_China.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg",
  },
  {
    country: "Colombia",
    fileName: "Flag_of_Colombia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Colombia.svg",
  },
  {
    country: "Comoros",
    fileName: "Flag_of_the_Comoros.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/9/94/Flag_of_the_Comoros.svg",
  },
  {
    country: "Democratic Republic of Congo",
    fileName: "Flag_of_the_Democratic_Republic_of_the_Congo.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/6/6f/Flag_of_the_Democratic_Republic_of_the_Congo.svg",
  },
  {
    country: "Republic of Congo",
    fileName: "Flag_of_the_Republic_of_the_Congo.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_the_Republic_of_the_Congo.svg",
  },
  {
    country: "Costa Rica",
    fileName: "Flag_of_Costa_Rica.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/f/f2/Flag_of_Costa_Rica.svg",
  },
  {
    country: "Croatia",
    fileName: "Flag_of_Croatia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Croatia.svg",
  },
  {
    country: "Cuba",
    fileName: "Flag_of_Cuba.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/b/bd/Flag_of_Cuba.svg",
  },
  {
    country: "Cyprus",
    fileName: "Flag_of_Cyprus.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Cyprus.svg",
  },
  {
    country: "Czech Republic",
    fileName: "Flag_of_the_Czech_Republic.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_Czech_Republic.svg",
  },
  {
    country: "Denmark",
    fileName: "Flag_of_Denmark.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg",
  },
  {
    country: "Djibouti",
    fileName: "Flag_of_Djibouti.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_Djibouti.svg",
  },
  {
    country: "Dominica",
    fileName: "Flag_of_Dominica.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/c/c4/Flag_of_Dominica.svg",
  },
  {
    country: "Dominican Republic",
    fileName: "Flag_of_the_Dominican_Republic.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_the_Dominican_Republic.svg",
  },
  {
    country: "East Timor",
    fileName: "Flag_of_East_Timor.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/2/26/Flag_of_East_Timor.svg",
  },
  {
    country: "Ecuador",
    fileName: "Flag_of_Ecuador.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/e/e8/Flag_of_Ecuador.svg",
  },
  {
    country: "Egypt",
    fileName: "Flag_of_Egypt.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Egypt.svg",
  },
  {
    country: "El Salvador",
    fileName: "Flag_of_El_Salvador.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_El_Salvador.svg",
  },
  {
    country: "Equatorial Guinea",
    fileName: "Flag_of_Equatorial_Guinea.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Equatorial_Guinea.svg",
  },
  {
    country: "Eritrea",
    fileName: "Flag_of_Eritrea.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_Eritrea.svg",
  },
  {
    country: "Estonia",
    fileName: "Flag_of_Estonia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/8/8f/Flag_of_Estonia.svg",
  },
  {
    country: "Eswatini",
    fileName: "Flag_of_Eswatini.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/f/fb/Flag_of_Eswatini.svg",
  },
  {
    country: "Ethiopia",
    fileName: "Flag_of_Ethiopia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/7/71/Flag_of_Ethiopia.svg",
  },
  {
    country: "Fiji",
    fileName: "Flag_of_Fiji.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Fiji.svg",
  },
  {
    country: "Finland",
    fileName: "Flag_of_Finland.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Finland.svg",
  },
  {
    country: "France",
    fileName: "Flag_of_France.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg",
  },
  {
    country: "Gabon",
    fileName: "Flag_of_Gabon.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/0/04/Flag_of_Gabon.svg",
  },
  {
    country: "The Gambia",
    fileName: "Flag_of_The_Gambia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_The_Gambia.svg",
  },
  {
    country: "Georgia",
    fileName: "Flag_of_Georgia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Georgia.svg",
  },
  {
    country: "Germany",
    fileName: "Flag_of_Germany.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg",
  },
  {
    country: "Ghana",
    fileName: "Flag_of_Ghana.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg",
  },
  {
    country: "Greece",
    fileName: "Flag_of_Greece.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Greece.svg",
  },
  {
    country: "Grenada",
    fileName: "Flag_of_Grenada.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Grenada.svg",
  },
  {
    country: "Guatemala",
    fileName: "Flag_of_Guatemala.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/e/ec/Flag_of_Guatemala.svg",
  },
  {
    country: "Guinea",
    fileName: "Flag_of_Guinea.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_Guinea.svg",
  },
  {
    country: "Guinea-Bissau",
    fileName: "Flag_of_Guinea-Bissau.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Guinea-Bissau.svg",
  },
  {
    country: "Guyana",
    fileName: "Flag_of_Guyana.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_Guyana.svg",
  },
  {
    country: "Haiti",
    fileName: "Flag_of_Haiti.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Haiti.svg",
  },
  {
    country: "Honduras",
    fileName: "Flag_of_Honduras.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/8/82/Flag_of_Honduras.svg",
  },
  {
    country: "Hungary",
    fileName: "Flag_of_Hungary.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/c/c1/Flag_of_Hungary.svg",
  },
  {
    country: "Iceland",
    fileName: "Flag_of_Iceland.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Iceland.svg",
  },
  {
    country: "India",
    fileName: "Flag_of_India.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
  },
  {
    country: "Indonesia",
    fileName: "Flag_of_Indonesia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg",
  },
  {
    country: "Iran",
    fileName: "Flag_of_Iran.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/c/ca/Flag_of_Iran.svg",
  },
  {
    country: "Iraq",
    fileName: "Flag_of_Iraq.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Iraq.svg",
  },
  {
    country: "Ireland",
    fileName: "Flag_of_Ireland.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/4/45/Flag_of_Ireland.svg",
  },
  {
    country: "Israel",
    fileName: "Flag_of_Israel.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
  },
  {
    country: "Italy",
    fileName: "Flag_of_Italy.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg",
  },
  {
    country: "Ivory Coast",
    fileName: "Flag_of_C%C3%B4te_d%27Ivoire.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_C%C3%B4te_d%27Ivoire.svg",
  },
  {
    country: "Jamaica",
    fileName: "Flag_of_Jamaica.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Jamaica.svg",
  },
  {
    country: "Japan",
    fileName: "Flag_of_Japan.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/en/9/9e/Flag_of_Japan.svg",
  },
  {
    country: "Jordan",
    fileName: "Flag_of_Jordan.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/c/c0/Flag_of_Jordan.svg",
  },
  {
    country: "Kazakhstan",
    fileName: "Flag_of_Kazakhstan.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kazakhstan.svg",
  },
  {
    country: "Kenya",
    fileName: "Flag_of_Kenya.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Kenya.svg",
  },
  {
    country: "Kiribati",
    fileName: "Flag_of_Kiribati.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kiribati.svg",
  },
  {
    country: "North Korea",
    fileName: "Flag_of_North_Korea.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/5/51/Flag_of_North_Korea.svg",
  },
  {
    country: "South Korea",
    fileName: "Flag_of_South_Korea.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_South_Korea.svg",
  },
  {
    country: "Kuwait",
    fileName: "Flag_of_Kuwait.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/a/aa/Flag_of_Kuwait.svg",
  },
  {
    country: "Kyrgyzstan",
    fileName: "Flag_of_Kyrgyzstan.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/c/c7/Flag_of_Kyrgyzstan.svg",
  },
  {
    country: "Laos",
    fileName: "Flag_of_Laos.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Laos.svg",
  },
  {
    country: "Latvia",
    fileName: "Flag_of_Latvia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Latvia.svg",
  },
  {
    country: "Lebanon",
    fileName: "Flag_of_Lebanon.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/5/59/Flag_of_Lebanon.svg",
  },
  {
    country: "Lesotho",
    fileName: "Flag_of_Lesotho.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/4/4a/Flag_of_Lesotho.svg",
  },
  {
    country: "Liberia",
    fileName: "Flag_of_Liberia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/b/b8/Flag_of_Liberia.svg",
  },
  {
    country: "Libya",
    fileName: "Flag_of_Libya.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/0/05/Flag_of_Libya.svg",
  },
  {
    country: "Liechtenstein",
    fileName: "Flag_of_Liechtenstein.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/4/47/Flag_of_Liechtenstein.svg",
  },
  {
    country: "Lithuania",
    fileName: "Flag_of_Lithuania.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Lithuania.svg",
  },
  {
    country: "Luxembourg",
    fileName: "Flag_of_Luxembourg.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/d/da/Flag_of_Luxembourg.svg",
  },
  {
    country: "Macedonia",
    fileName: "Flag_of_North_Macedonia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_North_Macedonia.svg",
  },
  {
    country: "Madagascar",
    fileName: "Flag_of_Madagascar.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Madagascar.svg",
  },
  {
    country: "Malawi",
    fileName: "Flag_of_Malawi.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/d/d1/Flag_of_Malawi.svg",
  },
  {
    country: "Malaysia",
    fileName: "Flag_of_Malaysia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_Malaysia.svg",
  },
  {
    country: "Maldives",
    fileName: "Flag_of_Maldives.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Maldives.svg",
  },
  {
    country: "Mali",
    fileName: "Flag_of_Mali.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_Mali.svg",
  },
  {
    country: "Malta",
    fileName: "Flag_of_Malta.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Malta.svg",
  },
  {
    country: "Marshall Islands",
    fileName: "Flag_of_the_Marshall_Islands.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/2/2e/Flag_of_the_Marshall_Islands.svg",
  },
  {
    country: "Mauritania",
    fileName: "Flag_of_Mauritania.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/4/43/Flag_of_Mauritania.svg",
  },
  {
    country: "Mauritius",
    fileName: "Flag_of_Mauritius.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Mauritius.svg",
  },
  {
    country: "Mexico",
    fileName: "Flag_of_Mexico.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Mexico.svg",
  },
  {
    country: "Federated States of Micronesia",
    fileName: "Flag_of_the_Federated_States_of_Micronesia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/e/e4/Flag_of_the_Federated_States_of_Micronesia.svg",
  },
  {
    country: "Moldova",
    fileName: "Flag_of_Moldova.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Moldova.svg",
  },
  {
    country: "Monaco",
    fileName: "Flag_of_Monaco.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/e/ea/Flag_of_Monaco.svg",
  },
  {
    country: "Mongolia",
    fileName: "Flag_of_Mongolia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Mongolia.svg",
  },
  {
    country: "Montenegro",
    fileName: "Flag_of_Montenegro.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Montenegro.svg",
  },
  {
    country: "Morocco",
    fileName: "Flag_of_Morocco.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Morocco.svg",
  },
  {
    country: "Mozambique",
    fileName: "Flag_of_Mozambique.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Mozambique.svg",
  },
  {
    country: "Myanmar",
    fileName: "Flag_of_Myanmar.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/8/8c/Flag_of_Myanmar.svg",
  },
  {
    country: "Namibia",
    fileName: "Flag_of_Namibia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Namibia.svg",
  },
  {
    country: "Nauru",
    fileName: "Flag_of_Nauru.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/3/30/Flag_of_Nauru.svg",
  },
  {
    country: "Nepal",
    fileName: "Flag_of_Nepal.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/9/9b/Flag_of_Nepal.svg",
  },
  {
    country: "Netherlands",
    fileName: "Flag_of_the_Netherlands.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg",
  },
  {
    country: "New Zealand",
    fileName: "Flag_of_New_Zealand.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/3/3e/Flag_of_New_Zealand.svg",
  },
  {
    country: "Nicaragua",
    fileName: "Flag_of_Nicaragua.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Nicaragua.svg",
  },
  {
    country: "Niger",
    fileName: "Flag_of_Niger.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/f/f4/Flag_of_Niger.svg",
  },
  {
    country: "Nigeria",
    fileName: "Flag_of_Nigeria.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_Nigeria.svg",
  },
  {
    country: "Norway",
    fileName: "Flag_of_Norway.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg",
  },
  {
    country: "Oman",
    fileName: "Flag_of_Oman.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Oman.svg",
  },
  {
    country: "Pakistan",
    fileName: "Flag_of_Pakistan.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg",
  },
  {
    country: "Palau",
    fileName: "Flag_of_Palau.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Palau.svg",
  },
  {
    country: "Palestine",
    fileName: "Flag_of_Palestine.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Palestine.svg",
  },
  {
    country: "Panama",
    fileName: "Flag_of_Panama.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/a/ab/Flag_of_Panama.svg",
  },
  {
    country: "Papua New Guinea",
    fileName: "Flag_of_Papua_New_Guinea.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/e/e3/Flag_of_Papua_New_Guinea.svg",
  },
  {
    country: "Paraguay",
    fileName: "Flag_of_Paraguay.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Paraguay.svg",
  },
  {
    country: "Peru",
    fileName: "Flag_of_Peru.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Peru.svg",
  },
  {
    country: "Philippines",
    fileName: "Flag_of_the_Philippines.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_the_Philippines.svg",
  },
  {
    country: "Poland",
    fileName: "Flag_of_Poland.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/en/1/12/Flag_of_Poland.svg",
  },
  {
    country: "Portugal",
    fileName: "Flag_of_Portugal.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg",
  },
  {
    country: "Qatar",
    fileName: "Flag_of_Qatar.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Qatar.svg",
  },
  {
    country: "Romania",
    fileName: "Flag_of_Romania.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Romania.svg",
  },
  {
    country: "Russia",
    fileName: "Flag_of_Russia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/en/f/f3/Flag_of_Russia.svg",
  },
  {
    country: "Rwanda",
    fileName: "Flag_of_Rwanda.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Rwanda.svg",
  },
  {
    country: "Saint Kitts and Nevis",
    fileName: "Flag_of_Saint_Kitts_and_Nevis.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Saint_Kitts_and_Nevis.svg",
  },
  {
    country: "Saint Lucia",
    fileName: "Flag_of_Saint_Lucia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Saint_Lucia.svg",
  },
  {
    country: "Saint Vincent and Grenadines",
    fileName: "Flag_of_Saint_Vincent_and_the_Grenadines.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/6/6d/Flag_of_Saint_Vincent_and_the_Grenadines.svg",
  },
  {
    country: "Samoa",
    fileName: "Flag_of_Samoa.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Samoa.svg",
  },
  {
    country: "San Marino",
    fileName: "Flag_of_San_Marino.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/b/b1/Flag_of_San_Marino.svg",
  },
  {
    country: "Sao Tome and Principe",
    fileName: "Flag_of_Sao_Tome_and_Principe.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Sao_Tome_and_Principe.svg",
  },
  {
    country: "Saudi Arabia",
    fileName: "Flag_of_Saudi_Arabia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg",
  },
  {
    country: "Senegal",
    fileName: "Flag_of_Senegal.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/f/fd/Flag_of_Senegal.svg",
  },
  {
    country: "Serbia",
    fileName: "Flag_of_Serbia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/f/ff/Flag_of_Serbia.svg",
  },
  {
    country: "Seychelles",
    fileName: "Flag_of_Seychelles.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Seychelles.svg",
  },
  {
    country: "Sierra Leone",
    fileName: "Flag_of_Sierra_Leone.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Sierra_Leone.svg",
  },
  {
    country: "Singapore",
    fileName: "Flag_of_Singapore.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Singapore.svg",
  },
  {
    country: "Slovakia",
    fileName: "Flag_of_Slovakia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/e/e6/Flag_of_Slovakia.svg",
  },
  {
    country: "Slovenia",
    fileName: "Flag_of_Slovenia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/f/f0/Flag_of_Slovenia.svg",
  },
  {
    country: "Solomon Islands",
    fileName: "Flag_of_the_Solomon_Islands.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Solomon_Islands.svg",
  },
  {
    country: "Somalia",
    fileName: "Flag_of_Somalia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_Somalia.svg",
  },
  {
    country: "South Africa",
    fileName: "Flag_of_South_Africa.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/a/af/Flag_of_South_Africa.svg",
  },
  {
    country: "South Sudan",
    fileName: "Flag_of_South_Sudan.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/7/7a/Flag_of_South_Sudan.svg",
  },
  {
    country: "Spain",
    fileName: "Flag_of_Spain.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/en/9/9a/Flag_of_Spain.svg",
  },
  {
    country: "Sri Lanka",
    fileName: "Flag_of_Sri_Lanka.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg",
  },
  {
    country: "Sudan",
    fileName: "Flag_of_Sudan.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Sudan.svg",
  },
  {
    country: "Suriname",
    fileName: "Flag_of_Suriname.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/6/60/Flag_of_Suriname.svg",
  },
  {
    country: "Sweden",
    fileName: "Flag_of_Sweden.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/en/4/4c/Flag_of_Sweden.svg",
  },
  {
    country: "Switzerland",
    fileName: "Flag_of_Switzerland_%28Pantone%29.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/0/08/Flag_of_Switzerland_%28Pantone%29.svg",
  },
  {
    country: "Syria",
    fileName: "Flag_of_Syria.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/5/53/Flag_of_Syria.svg",
  },
  {
    country: "Tajikistan",
    fileName: "Flag_of_Tajikistan.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Tajikistan.svg",
  },
  {
    country: "Tanzania",
    fileName: "Flag_of_Tanzania.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tanzania.svg",
  },
  {
    country: "Thailand",
    fileName: "Flag_of_Thailand.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_Thailand.svg",
  },
  {
    country: "Togo",
    fileName: "Flag_of_Togo.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/6/68/Flag_of_Togo.svg",
  },
  {
    country: "Tonga",
    fileName: "Flag_of_Tonga.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Tonga.svg",
  },
  {
    country: "Trinidad and Tobago",
    fileName: "Flag_of_Trinidad_and_Tobago.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Trinidad_and_Tobago.svg",
  },
  {
    country: "Tunisia",
    fileName: "Flag_of_Tunisia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Tunisia.svg",
  },
  {
    country: "Turkey",
    fileName: "Flag_of_Turkey.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg",
  },
  {
    country: "Turkmenistan",
    fileName: "Flag_of_Turkmenistan.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Turkmenistan.svg",
  },
  {
    country: "Tuvalu",
    fileName: "Flag_of_Tuvalu.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tuvalu.svg",
  },
  {
    country: "Uganda",
    fileName: "Flag_of_Uganda.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/4/4e/Flag_of_Uganda.svg",
  },
  {
    country: "Ukraine",
    fileName: "Flag_of_Ukraine.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Ukraine.svg",
  },
  {
    country: "United Arab Emirates",
    fileName: "Flag_of_the_United_Arab_Emirates.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg",
  },
  {
    country: "United Kingdom",
    fileName: "Flag_of_the_United_Kingdom.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg",
  },
  {
    country: "United States",
    fileName: "Flag_of_the_United_States.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg",
  },
  {
    country: "Uruguay",
    fileName: "Flag_of_Uruguay.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Uruguay.svg",
  },
  {
    country: "Uzbekistan",
    fileName: "Flag_of_Uzbekistan.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Uzbekistan.svg",
  },
  {
    country: "Vanuatu",
    fileName: "Flag_of_Vanuatu.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Vanuatu.svg",
  },
  {
    country: "Vatican City",
    fileName: "Flag_of_the_Vatican_City.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_the_Vatican_City.svg",
  },
  {
    country: "Venezuela",
    fileName: "Flag_of_Venezuela.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/0/06/Flag_of_Venezuela.svg",
  },
  {
    country: "Vietnam",
    fileName: "Flag_of_Vietnam.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Vietnam.svg",
  },
  {
    country: "Yemen",
    fileName: "Flag_of_Yemen.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Yemen.svg",
  },
  {
    country: "Zambia",
    fileName: "Flag_of_Zambia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/0/06/Flag_of_Zambia.svg",
  },
  {
    country: "Zimbabwe",
    fileName: "Flag_of_Zimbabwe.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/6/6a/Flag_of_Zimbabwe.svg",
  },
  {
    country: "Abkhazia",
    fileName: "Flag_of_the_Republic_of_Abkhazia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/7/7a/Flag_of_the_Republic_of_Abkhazia.svg",
  },
  {
    country: "Artsakh",
    fileName: "Flag_of_Artsakh.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/3/3d/Flag_of_Artsakh.svg",
  },
  {
    country: "Cook Islands",
    fileName: "Flag_of_the_Cook_Islands.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/3/35/Flag_of_the_Cook_Islands.svg",
  },
  {
    country: "Kosovo",
    fileName: "Flag_of_Kosovo.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/1/1f/Flag_of_Kosovo.svg",
  },
  {
    country: "Niue",
    fileName: "Flag_of_Niue.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Niue.svg",
  },
  {
    country: "Turkish Republic of Northern Cyprus",
    fileName: "Flag_of_the_Turkish_Republic_of_Northern_Cyprus.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/1/1e/Flag_of_the_Turkish_Republic_of_Northern_Cyprus.svg",
  },
  {
    country: "Sahrawi Arab Democratic Republic",
    fileName: "Flag_of_the_Sahrawi_Arab_Democratic_Republic.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/2/26/Flag_of_the_Sahrawi_Arab_Democratic_Republic.svg",
  },
  {
    country: "Somaliland",
    fileName: "Flag_of_Somaliland.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/4/4d/Flag_of_Somaliland.svg",
  },
  {
    country: "South Ossetia",
    fileName: "Flag_of_South_Ossetia.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/1/12/Flag_of_South_Ossetia.svg",
  },
  {
    country: "Republic of China",
    fileName: "Flag_of_the_Republic_of_China.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/7/72/Flag_of_the_Republic_of_China.svg",
  },
  {
    country: "Transnistria",
    fileName: "Flag_of_Transnistria_%28state%29.svg",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Transnistria_%28state%29.svg",
  },
];
