
import { Card, CardTitle, Col, Container, Row } from 'reactstrap';
import { dataService } from '@services';
import { CustomBarChart, CustomLineChart, CustomPieChart, CustomTable } from '@view';
import './suq.page.css';

export const Suq = () => {
  return (
    <Container className="mt-3" fluid>
      <h1>Some Unanswered Questions Quiz Results</h1>
      <Row className="mb-3" lg="2" md="1">
        <Col>
          <Card>
            <CustomLineChart
              data={dataService.getFinalScoreLineChartData()}
              title="Final Score"
              yAxis="finalScore"
              yAxisDisplayName="Final Score"
            />
          </Card>
        </Col>
        <Col>
          <Card>
            <CustomLineChart
              data={dataService.getPositionLineChartData()}
              reversed={true}
              title="Pole Position"
              yAxis="position"
              yAxisDisplayName="Position"
            />
          </Card>
        </Col>
      </Row>
      <Row className="mb-3" lg="2" md="1">
        <Col>
          <Card>
            <CardTitle>Medals</CardTitle>
            <CustomPieChart data={dataService.getPolePositions()} />
          </Card>
        </Col>
        <Col>
          <Card>
            <CardTitle>Player Count</CardTitle>
            <CustomPieChart data={dataService.getPlayerCountData()} />
          </Card>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Card>
            <CardTitle>Categories</CardTitle>
            <CustomBarChart />
          </Card>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <CustomTable />
        </Col>
      </Row>
    </Container>
  );
};
