import { Container } from "reactstrap";

export const About = () => (
  <Container className="center mt-3">
    <p>
      Created by{" "}
      <a
        href="https://tomosewe.com"
        title="https://tomosewe.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Tomos Ewe
      </a>
    </p>
    <div>
      Icons made by{" "}
      <a
        href="https://www.freepik.com"
        title="Freepik"
        target="_blank"
        rel="noopener noreferrer"
      >
        Freepik
      </a>{" "}
      from{" "}
      <a
        href="https://www.flaticon.com/"
        title="Flaticon"
        target="_blank"
        rel="noopener noreferrer"
      >
        www.flaticon.com
      </a>
    </div>
  </Container>
);
