import { useState, useEffect } from 'react';
import { getTopRatedMovies, MovieResult } from '@services';
import { Col, Container, Row } from 'reactstrap';

export const Oscars = () => {
  const [movies, setMovies] = useState<MovieResult[]>();

  useEffect(() => {
    const fetchMovies = async () => {
      const response = await getTopRatedMovies();
      const movies = response.results;
      console.log('response', response);
      setMovies(movies);
    };

    fetchMovies();
  }, []);

  return (
    <Container fluid="md">
      <h1>OSCARS!</h1>
      {movies?.map(({ title, overview, id }) => (
        <Row key={id}>
          <Col>
            <article>
              <h3>{title}</h3>
              <p>{overview}</p>
            </article>
          </Col>
        </Row>
      ))}
    </Container>
  );
};
