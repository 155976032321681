import {
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

type Props = {
  data: any;
};
export const CustomPieChart = ({
  data,
}: Props) => {
  return (
    <ResponsiveContainer width={'100%'} height={250}>
      <PieChart width={400} height={250}>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={50}
          fill="#8884d8"
          label={(entry) => `${entry.name}: ${entry.value}`}
        />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};
