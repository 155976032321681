import classNames from "classnames";
import { Flag } from "@models";

export const FlagCard = ({
  className,
  flag,
  onClick,
  showCountry = true,
}: {
  className?: string;
  flag: Flag;
  onClick?: () => void;
  showCountry?: boolean;
}) => {
  const classes = classNames(className, "card");

  const { imageUrl, country } = flag;

  return (
    <div className={classes} onClick={onClick}>
      <div className="card__image-container">
        <img className="card__image" src={imageUrl} alt={`${country}-flag`} />
      </div>
      {showCountry && <p className="card__title">{country}</p>}
    </div>
  );
};
