import * as store from "store"; // https://github.com/marcuswestin/store.js for local storage

class HighScoreService {
  getHighScore = (key: string): number => {
    const highScore = store.get(key);

    return highScore || 0;
  };

  updateHighScoreIfRequired = (key: string, newScore: number) => {
    const highScore = this.getHighScore(key);

    if (!highScore || newScore > highScore) {
      this.saveHighScore(key, newScore);
    }
  }

  saveHighScore = (key: string, score: number) => {
    store.set(key, score);
  }
}

export const highScoreService = new HighScoreService();
