import { groupBy } from "lodash";
import { Container, Row, Col } from "reactstrap";
import { resources } from "@data";
import { ResourceCard } from "@view";

export const Home = () => {
  const dataGroupedByCategory = groupBy(resources, "category");

  return (
    <Container fluid className="mt-2">
      <Row>
        {Object.entries(dataGroupedByCategory).map(
          ([category, categoryData]) => (
            <Col key={category} className="mb-2" xs="12" lg="3">
              <ResourceCard category={category} categoryData={categoryData} />
            </Col>
          )
        )}
      </Row>
    </Container>
  );
};
